<template>
  <v-card class="block-report" flat tile>
    <v-card-text>
      <v-row>
        <template v-if="loading">
          <v-col cols="12">
            <div class="flex flex-col gap-5" v-if="!this.isEmpty(optionChartBar.labels)">
              <h4  class="subtitle-2 primary--text font-weight-black">
              Gráfico 1 de 1
              </h4>
              <p class="text-center">{{ title }}</p>
              <pie-chart
                ref="report6"
                chart-id="report6"
                :chart-data="optionChartBar"
                :title="title"
              />
            </div>
            <template v-else>
              <p  class="text-center text-black text-h5 my-10">No se ha encontrado ningún resultado.</p>
            </template>
          </v-col>
          <v-col cols="12" class="text-left" v-if="!this.isEmpty(optionChartBar.labels)">
            <v-simple-table class="table-report table-small" :dense="true">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Responsable</th>
                    <th class="text-left">Fichas totales creadas</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in dataTable" :key="index">
                    <td>{{ item.first_name + " " + item.last_name }}</td>
                    <td>{{ item.total }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </template>
        <template v-else>
          <v-col cols="12" class="text-center mt-5">
            <v-progress-circular
              :size="22"
              class="d-inline-block mr-3"
              :width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <span class="primary--text">Cargando reporte</span>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import PieChart from "./charts/PieChart";
import { generatePalette } from "../../utils/palette";

export default {
  components: { PieChart },
  props: {
    tab: {
      type: String,
      default: "",
    },
    filterData: {
      type: String,
      default: "",
    },
    filterList: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    isFlag: false,
    loading: false,
    optionChartBar: {},
    dataTable: [],
    dataTotal: 0,
    backgroundColor: [],
  }),
  computed: {},
  watch: {
    filterData() {
      this.$nextTick(() => {
        this.fetchChart(true);
      });
    },
  },
  mounted() {
    this.fetchChart();
  },
  methods: {
    async fetchChart() {
      this.loading = false;
      const { data } = await this.axios.get(
        `/api/reports/seven${this.filterData}`,
      );

      if (data.success) {
        let dataChart = data.data;
        this.backgroundColor = [...generatePalette(dataChart.length)];

        console.log(dataChart);

        this.optionChartBar = {
          labels: [...dataChart.map((x) => x.first_name + " " + x.last_name)],
          datasets: [
            {
              backgroundColor: this.backgroundColor,
              data: [...dataChart.map((x) => x.total)],
            },
          ],
        };

        this.dataTable = dataChart;

        this.loading = true;
      }
    },
    formatDecimal(val) {
      return parseFloat((val * 100).toFixed(2));
    },
  },
};
</script>
