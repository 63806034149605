<template>
  <div>
    <v-btn
      class="mr-2 my-0"
      small
      depressed
      :ripple="!loadWord"
      @click="onSubmitWork()"
    >
      <v-progress-circular
        v-if="loadWord"
        :size="20"
        class="d-inline-block mr-3"
        :width="2"
        indeterminate
        color="black"
      ></v-progress-circular>
      {{ loadWord ? "Descargando ficha" : "Descargar ficha" }}
      <v-icon v-if="!loadWord" color="grey">class</v-icon>
    </v-btn>
    <a id="urlDownloadWord" :href="urlDownload" class="d-none" target="_blank"
      >download...</a
    >
  </div>
</template>
<script>
import {v4} from "uuid";

export default {
  name: "ExportWord",
  props: {
    id: { type: String, default: "" },
    apiModule: { type: Object, default: () => {} },
  },
  data: () => ({
    urlDownload: "",
    loadWord: false,
  }),
  methods: {
    async onSubmitWork() {
      if (this.loadWord) return false;
      this.loadWord = true;

      this.axios(
        {
          url: `/api/${this.apiModule.module}/${this.apiModule.submodule}/export/word/${this.id}`,
          responseType: 'arraybuffer',
        }
      ).then(({ data }) => {
        let blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        })

        let file = `${v4()}.docx`;

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = file;
        link.click();

        this.$toast.success(`El archivo ${file} se ha descargado correctamente.`)

        this.loadWord = false;
      });
    },
  },
};
</script>
