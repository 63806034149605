<template>
  <div>
    <v-form
      ref="formFileUpdate"
      v-model="validUpdate"
      :lazy-validation="lazyUpdate"
    >
      <template v-if="isMultiple">
        <h4 v-if="title !== ''" class="mb-2 card-input-title">{{ title }}</h4>
        <template v-if="!isTable">
          <v-file-input
            v-model="files"
            class="pt-0 input-custom-56 mb-0"
            outlined
            multiple
            :disabled="disabled"
            :hide-details="isEmpty(textError)"
            :rules="rulesInput"
            :placeholder="formatPlaceholder(itemFile)"
            @change="handleFileUpload"
          ></v-file-input>
        </template>
        <p v-if="!isEmpty(attachments)" class="mb-0">
          <a
            v-if="attachments[attachments.length - 1].url"
            :href="formatURL(attachments[attachments.length - 1].url)"
            target="_blank"
          >Ver archivo cargado</a
          >
          <template v-else>
            <v-btn depressed color="grey darken-2" class="ma-0" disabled>
              <span style="text-transform:initial;">Ver archivo cargado</span>
            </v-btn>
          </template>
        </p>
      </template>
      <template v-else>
        <v-file-input
          v-model="files"
          class="pt-0 input-custom-56 mb-0"
          outlined
          :disabled="disabled"
          :hide-details="isEmpty(textError)"
          :rules="rulesInput"
          :placeholder="formatPlaceholder(itemFile)"
          @change="handleFileUpload"
        ></v-file-input>
        <!-- <p v-if="itemFile" class="mb-0">
            <v-btn color="primary" @click="descargarArchivo(itemFile)"  class="ma-2 white--text"   >
              Descargar
            </v-btn>
        </p> -->

        <p v-if="!isEmpty(itemFile)" class="mb-0">
          <a :href="baseURL+'/'+itemFile" target="_blank"
          >Ver archivo cargado</a
          >
        </p>
      </template>
    </v-form>
  </div>
</template>
<script>
export default {
  name: "UploadFile",
  props: {
    title: { type: String, default: "" },
    showName: { type: Boolean, default: false },
    isMultiple: { type: Boolean, default: true },
    attachments: {
      type: Array,
      default() {
        return [];
      },
    },
    isTable: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    // eslint-disable-next-line vue/require-prop-types
    itemFile: { default: null },
    textError: { type: String, default: "" },
  },
  data: () => ({
    validUpdate: false,
    lazyUpdate: false,
    loadUpdate: false,
    selectOneFile: null,
    files: null,
    nameFile: null,
    titleFile: null,
    rulesInput: [],
  }),
  watch: {
    showName(val) {
      if (!val) {
        this.titleFile = null;
      }
    },
    attachments: {
      deep: true,
      handler(val) {
        if (this.showName) {
          if (val.length) {
            this.titleFile = val[val.length - 1].name;
            if (val[val.length - 1].file) {
              this.nameFile = val[val.length - 1].file.name;
            }
          }
        }
      },
    },
    textError: {
      immediate: true,
      handler(val) {
        this.rulesInput = this.isEmpty(val) ? [] : [() => val];
      },
    },
  },
  mounted() {
    if (this.showName) {
      this.titleFile = null;
    }
  },
  methods: {
    formatPlaceholder() {
      if (!this.isEmpty(this.itemFile)) {
        const formatFile = this.itemFile.split("/");
        return formatFile[formatFile.length - 1];
      } else {
        return "Seleccione un archivo.";
      }
    },
    clickUpdate() {
      this.$refs[this.isMultiple ? "inputMultiFile" : "inputOneFile"].click();
    },
    handleFileUpload(e) {
      if (e) {
        if (this.isMultiple) {
          this.files = e;
          this.nameFile = this.files.name;
          this.$emit("update", this.files);
        } else {
          this.selectOneFile = e;
          this.nameFile = this.selectOneFile.name;
          this.$emit("update", this.selectOneFile);
        }
      }
    },
    validateError() {
      this.$refs.formFileUpdate.validate();
    },
    onChangeName(val) {
      this.$emit("update-name", val);
    },
    clearData() {
      this.titleFile = null;
      this.nameFile = null;
      this.files = null;
    },

    formatURL(url) {
      if (url.includes("storage")) {
        return `${this.baseURL}/${url}`;
      }
      return url;
    },

    descargarArchivo(archivo) {
      if (archivo) {
        // Crear una URL del objeto File
        const url = URL.createObjectURL(archivo);

        // Crear un enlace temporal y hacer clic en él para iniciar la descarga
        const a = document.createElement('a');
        a.href = url;
        a.download = archivo.name;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();

        // Limpiar después de la descarga
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        alert("Selecciona un archivo antes de intentar descargar.");
      }
    }
  },
};
</script>
