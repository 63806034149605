<template>
  <v-navigation-drawer
    id="adminDrawer"
    v-model="navigationDrawer"
    class="parent--drawer"
    app
    clipped
    fixed
    permanent
    :width="widthDrawer"
  >
    <v-row class="fill-height" no-gutters>
      <v-navigation-drawer
        v-if="getMenu[0]"
        width="60"
        mini-variant-width="60"
        mini-variant
      >
        <vue-perfect-scrollbar
          class="drawer-menu--scroll"
          :settings="scrollSettings"
        >
          <v-list dense nav class="grow px-0">
            <template v-for="item in getMenu[0]">
              <v-list-item
                v-if="validateRole(item)"
                :key="`menu-${item.key}`"
                :class="`${validateActiveMenu(0, item)} py-0 px-1`"
                :ripple="false"
                @click="validateRoute(item, 0)"
              >
                <v-list-item-action>
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="menu-link__status"
                        :ripple="false"
                        depressed
                        icon
                        v-on="on"
                      >
                        <span class="drawer-icon-svg">
                          <img
                            :src="`${baseURL}/img/icons/white/${item.icon}`"
                          />
                        </span>
                      </v-btn>
                    </template>
                    <span>{{ item.label }}</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </vue-perfect-scrollbar>
      </v-navigation-drawer>
      <template v-if="showModuleDrawer">
        <modules-drawer :modules="listModules"></modules-drawer>
        <modules-drawer
          v-if="
            $route.name === 'SwitchModulePage' &&
              activeModule.slug !== 'propiedades-mineras'
          "
          :modules="activeModule.submodules"
          :is-submodules="true"
        ></modules-drawer>
      </template>
      <settings-drawer v-if="isSettings"></settings-drawer>
    </v-row>
  </v-navigation-drawer>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions, mapState } from "vuex";
import { isEmpty, findIndex } from "lodash";
import ModulesDrawer from "./ModulesDrawer";
import SettingsDrawer from "./SettingsDrawer";
import { MenuAdmin } from "@/api/menu";
const urlsSettings = [
  "SettingsPage",
  "CopyPage",
  "TermPage",
  "PoliticPage",
  "NotificationPage",
  "MastersPage",
];

export default {
  name: "AdminDrawer",
  components: {
    VuePerfectScrollbar,
    ModulesDrawer,
    SettingsDrawer,
  },
  data: () => ({
    id: "",
    typeModule: "",
    typeSubmodule: "",
    mini: false,
    // drawer: true,
    menus: MenuAdmin,
    countSub: 0,
    submenus: [],
    validateDrawer: false,
    // isDashboardPage: false,
    scrollSettings: {
      maxScrollbarLength: 160,
    },
    pathRoute: null,
    isToDo: false,
    navigationDrawer: true,
  }),
  computed: {
    ...mapState({
      listModules: (state) => state.modules.modules,
      activeModule: (state) => state.modules.activeModule,
      toggleDrawer: (state) => state.session.toggleDrawer,
    }),
    showModuleDrawer() {
      const listMenu = [
        "SubModulesPage",
        "SwitchModulePage",
        "SupervisionPage",
        "JudicialProcessPage",
      ];
      return listMenu.includes(this.$route.name);
    },
    getMenu() {
      return this.$store.getters["menuAdmin/getMenu"];
    },
    getListActive() {
      return this.$store.getters["menuAdmin/getListActive"];
    },
    widthDrawer() {
      const { name = null } = this.pathRoute;
      if (
        isEmpty(this.pathRoute) ||
        name === "UserPage" ||
        name === "RolesPage" ||
        name === "TodoPage"
      ) {
        return 60;
      } else if (urlsSettings.includes(name)) {
        return name === "SettingsPage" ? 60 : 120;
      } else if (name === "DashboardPage") {
        return 60;
      } else {
        if (name === "SubModulesPage" || name === "SwitchModulePage") {
          return name === "SubModulesPage" ||
            this.activeModule.slug === "propiedades-mineras"
            ? 120
            : 180;
        } else {
          if (this.validateDrawer) {
            return 250 + 60 * this.getMenu.length;
          } else {
            return 60 * this.getMenu.length;
          }
        }
      }
    },
    isSettings() {
      if (isEmpty(this.pathRoute)) {
        return false;
      } else {
        const { name } = this.pathRoute;
        return urlsSettings.includes(name);
      }
    },
  },
  watch: {
    toggleDrawer: {
      immediate: true,
      handler(val) {
        this.navigationDrawer = val;
      },
    },
    $route(val) {
      const { name, params } = val;
      this.id = params.id || "";
      this.typeModule = params.typeModule || "";
      this.typeSubmodule = params.typeSubmodule || "";
      this.validateDrawer =
        name === "LastLevelUserPage" ||
        name === "LastLevelAdminPage" ||
        name === "SwitchModulePage";
      this.pathRoute = val;
      if (name === "AlertPage" || name === "TodoPage") {
        this.validateExtra();
      }
      if (name === "DashboardPage") {
        this.$store.dispatch(`menuAdmin/setMenu`, {
          menu: MenuAdmin,
          init: true,
          index: 0,
        });
      }
    },
  },
  created() {
    const { name, params } = this.$route;
    this.id = params.id || "";
    this.typeModule = params.typeModule || "";
    this.typeSubmodule = params.typeSubmodule || "";
    if (name === "AlertPage" || name === "TodoPage") {
      this.validateExtra();
    }
    this.validateDrawer =
      name === "LastLevelUserPage" ||
      name === "LastLevelAdminPage" ||
      name === "SwitchModulePage";
    this.pathRoute = this.$route;
  },
  mounted() {
    this.navigationDrawer = this.toggleDrawer;
    this.$store.dispatch(`menuAdmin/setMenu`, {
      menu: MenuAdmin,
      init: true,
      index: 0,
    });
  },
  methods: {
    ...mapActions(["setDrawer"]),
    validateRole(item) {
      if (this.roleUser === "admin" && item.key === "usuarios") {
        return false;
      } else if (
        this.roleUser === "visualizer" &&
        item.key !== "modules" &&
        item.key !== "reportes" &&
        item.key !== "to-do" &&
        item.key !== "mapa"
      ) {
        return false;
      } else if (this.roleUser === "executor" && item.key !== "to-do") {
        return false;
      }
      return true;
    },
    validateActiveMenu(parentIndex, item) {
      if (this.getListActive.length) {
        if (isEmpty(this.getListActive[parentIndex])) {
          return "";
        }
        return this.getListActive[parentIndex].key === item.key
          ? "menu-active"
          : "";
      }
      return "";
    },
    validateRoute(menu, parentIndex = 1) {
      if (menu.key === "modules") {
        if (!menu.leaf) {
          this.$store.dispatch(`menuAdmin/setMenu`, {
            menu: MenuAdmin,
            init: true,
            index: 0,
          });
          this.$store.dispatch(`menuAdmin/setMenuActive`, menu);
          this.$store.dispatch(`menuAdmin/setListActive`, {
            active: menu,
            init: true,
            index: 0,
          });
        }
        this.$router.push({ name: "ModulesPage" });
      } else {
        const settings = [
          "TermPage",
          "PoliticPage",
          "CopyPage",
          "NotificationPage",
          "MastersPage",
        ];
        if (!menu.leaf) {
          this.$store.dispatch("menuAdmin/setMenuActive", menu);

          this.$store.dispatch("menuAdmin/setListActive", {
            active: menu,
            init: parentIndex === 0,
            index: parentIndex,
          });
          let menuParent = null;
          if (parentIndex > 0) {
            const findInx = findIndex(this.getMenu[parentIndex - 1], {
              key: this.getListActive[parentIndex - 1].key,
            });
            menuParent = this.getMenu[parentIndex - 1][findInx].submenu;
          } else {
            menuParent = this.getMenu[parentIndex];
          }

          this.$store.dispatch("menuAdmin/setMenu", {
            menu: menuParent,
            init: parentIndex === 0,
            index: parentIndex,
          });

          if (
            menu.name === "ReportPage" ||
            menu.name === "TodoPage" ||
            menu.name === "UserPage" ||
            menu.name === "SettingsPage"
          ) {
            this.$router.push({ name: menu.name });
          } else {
            if (settings.includes(menu.name)) {
              this.$router.push({ name: menu.name });
            } else if (parentIndex === 0) {
              this.$router.push({
                name: "Level1MenuPage",
                params: { id: menu.key },
              });
            } else if (parentIndex === 1) {
              this.$router.push({
                name: "Level2MenuPage",
                params: { id: this.id, typeModule: menu.key },
              });
            }
          }
        } else {
          this.$store.dispatch("menuAdmin/setMenuActive", menu);

          this.$store.dispatch("menuAdmin/setListActive", {
            active: menu,
            init: parentIndex === 0,
            index: parentIndex,
          });
          if (menu.name === "ProcessPage") {
            this.$router.push({
              name: menu.name,
              params: { id: this.id, typeModule: menu.key },
            });
          } else if (settings.includes(menu.name)) {
            this.$router.push({ name: menu.name, params: { id: this.id } });
          } else if (menu.name === "UserPage" || menu.name === "MapPage") {
            this.$router.push({ name: menu.name });
          } else {
            this.$router.push({
              name: "SwitchModulePage",
              params: {
                id: this.id,
                typeModule: this.typeModule,
                typeSubmodule: menu.key,
              },
            });
          }
        }
      }
    },
    validateExtra() {
      this.$store.dispatch("menuAdmin/setMenu", {
        menu: MenuAdmin,
        init: true,
        index: 0,
      });
    },
  },
};
</script>
<style lang="scss">
#adminDrawer {
  // overflow: hidden;
  .drawer-menu--scroll {
    height: calc(100vh - 48px);
    overflow: auto;
  }
  .drawer-filter--scroll {
    height: calc(100vh - 65px);
    overflow: auto;
  }
  .v-navigation-drawer__content {
    overflow-y: hidden;
  }
}
</style>
