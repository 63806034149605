import Vue from "vue";
import App from "./App.vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
// Router
import router from "./router/";
// Store
import store from "./store/";
// Http
import axios from "./http";
// ui
import vuetify from "./plugins/vuetify";
import VueScrollTo from "vue-scrollto";
import VueContenteditable from "vue-contenteditable";
import VueAnalytics from "vue-analytics";
import "./registerServiceWorker";
import Vue2Editor from "vue2-editor";

// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

// V-mask
import { VueMaskDirective } from "v-mask";

import firebase from "firebase/app";
import { firebaseConfig } from "./firebase/config";
import "firebase/messaging";

const firebaseApp = firebase.initializeApp(firebaseConfig);
window.firebase = firebase;
// window.firebaseMessaging = firebaseApp.messaging();
// mixins
import Globals from "./mixins";
// scss
import "./scss/app.scss";

// use | directives
Vue.directive("mask", VueMaskDirective);

// use | mixins
Vue.use(VueScrollTo);
Vue.use(VueContenteditable);
// Vue.use(VueFire);
Vue.use(VueAnalytics, {
  id: "UA-170640418-1",
});
Vue.use(Vue2Editor);
Vue.mixin(Globals);
// Vue.prototype._ = _;
Vue.prototype.axios = axios;
Vue.prototype.$messaging = firebase.messaging.isSupported()
  ? firebaseApp.messaging()
  : null;
Vue.prototype.$eventBus = new Vue();
Vue.config.productionTip = false;

Vue.filter('stripHTML', function (value) {
  let text = ''
  const parser = new DOMParser();
  const dom = parser.parseFromString(value, "text/html");
  let div = dom.querySelector('body>*');
  if(div){
    text = div.textContent || div.innerText || ''
  }
  return text
});

const options = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
};

Vue.use(Toast, options);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
