const baseInstance = {
  id: null,
  attachment: null,
  pending_tasks: [],
  comments: null,
  attachment_disabled : 0
};

export class baseIncidencia {
  constructor(data={}) {
    this.activeTab = 0;
    this.flagEdit = false;
    this.id = data.id || null;
    this.stage_id = data.parent_id || null;
    this.court = data.court || "";
    this.room = data.room || "";
    this.comments = data.comments || null;
    this.file_code = data.file_code || "";
    this.changeInstance =  {
      one: false,
      two: false,
      three: false,
      four: false,
      five: false,
      six: false,
      seven: false,
      eight: false,
      nine: false
    };

    // Inicializamos las propiedades dinámicamente según el número de instancias
    for (let i = 1; i <= 9; i++) {
      const instanceKey = `instance_${i}`;
      this[instanceKey] = { ...baseInstance, ...data[instanceKey] };
    }

  }
}

/* export class baseIncidencia {
  constructor() {
      this.activeTab = 0,
      this.id = null,
      this.stage_id = null,
      this.court = "",
      this.comments = null,
      this.file_code = "",
      this.changeInstance =  {
        one: false,
        two: false,
        three: false,
        four: false,
        five: false,
        six: false,
        seven: false,
        eight: false,
        nine: false
      },
      this.instance_1 = baseInstance,
      this.instance_2 = baseInstance,
      this.instance_3 = baseInstance,
      this.instance_4 = baseInstance,
      this.instance_5 = baseInstance,
      this.instance_6 = baseInstance,
      this.instance_7 = baseInstance,
      this.instance_8 = baseInstance,
      this.instance_9 = baseInstance

  }
} */

export class IJudicialProcess {
  constructor() {
    this.id = null;
    this.amount = null;
    this.cargo_study = {};
    this.complaining_party = null;
    this.controversial_matter = null;
    this.file_code = null;
    this.finished = 0;
    this.project = {};
    this.responsible = {};
    this.secondary_responsible = {};
    this.respondent_party = null;
    this.stage_1 = {
      id: null,
      court: {},
      comments: null,
      incidencias: [],
      instance_1: baseInstance,
      instance_2: baseInstance,
      instance_3: baseInstance,
      instance_4: baseInstance,
      instance_5: baseInstance,
      instance_6: baseInstance,
      instance_7: baseInstance,
      instance_8: baseInstance,
      instance_9: baseInstance,

    };
    this.stage_2 = {
      id: null,
      room: null,
      comments: null,
      incidencias: [],
      instance_1: baseInstance,
      instance_2: baseInstance,
      instance_3: baseInstance,
      instance_4: baseInstance,
      instance_5: baseInstance,
      instance_6: baseInstance,
      instance_7: baseInstance,
    };
    this.stage_3 = {
      id: null,
      room: null,
      comments: null,
      incidencias: [],
      instance_1: baseInstance,
      instance_2: baseInstance,
      instance_3: baseInstance,
      instance_4: baseInstance,
      instance_5: baseInstance,
      instance_6: baseInstance,
      instance_7: baseInstance,
    };
  }
}
