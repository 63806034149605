<template>
  <v-dialog
    v-model="dialogNew"
    content-class="dialog-custom"
    persistent
    open-on-focus
    scrollable
    max-width="950px"
    @keydown.esc="onCloseDialogUpdate()"
  >
    <v-card>
      <v-card-title class="py-2">
        <span class="headline">{{
          typeDialog === "new" ? "Nueva Supervisión" : "Supervisión"
        }}</span>
        <v-spacer></v-spacer>
        <export-word
          v-if="typeDialog === 'edit' && !loadDetail"
          :id="selectedItem.id"
          :api-module="apiByModule"
        ></export-word>
        <v-btn
          slot="activator"
          class="m-0"
          icon
          fab
          small
          @click="onCloseDialogUpdate()"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-col v-if="loadDetail" cols="12">
        <v-progress-linear
          color="primary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
      <vue-perfect-scrollbar
        v-show="!loadDetail"
        class="drawer-menu--scroll"
        :settings="scrollSettings"
        :swicher="!loadDetail"
      >
        <v-card-text class="pb-0">
          <v-form
            ref="formUpdate"
            v-model="validUpdate"
            :lazy-validation="lazyUpdate"
          >
            <v-row>
              <v-col
                v-if="typeDialog !== 'new' && selectedItem && selectedItem.id"
                cols="4"
                class="py-0"
              >
                <v-text-field
                  v-model="selectedItem.id"
                  disabled
                  outlined
                  label="Código de expediente*"
                  height="56"
                  hide-details
                  class="pt-0 input-custom-56"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-autocomplete
                  v-model="selectedItem.authority"
                  class="pt-0 mb-3 input-custom-56"
                  label="Autoridad*"
                  item-text="label"
                  item-value="id"
                  height="56"
                  outlined
                  return-object
                  :hide-details="isEmpty(listError.authority_id)"
                  :rules="rulesAuthority"
                  :items="listAuthority.options"
                  :disabled="disabledOptions"
                  @input="onChangeAuthority"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-autocomplete
                  v-model="selectedItem.situation"
                  class="pt-0 input-custom-56"
                  label="Situación*"
                  item-text="label"
                  item-value="id"
                  height="56"
                  outlined
                  return-object
                  :hide-details="isEmpty(listError.situation_id)"
                  :rules="rulesSituation"
                  :items="listSituations.options"
                  :disabled="disabledOptions"
                  @input="onChangeSituation"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-autocomplete
                  v-model="selectedItem.project"
                  class="pt-0 input-custom-56"
                  label="Proyecto*"
                  item-text="label"
                  item-value="id"
                  outlined
                  height="56"
                  return-object
                  :hide-details="isEmpty(listError.project_id)"
                  :rules="rulesProject"
                  :items="listProjects.options"
                  :disabled="disabledOptions"
                  @input="onChangeProject"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" class="py-0">
                <selector-user
                  ref="responsibleMain"
                  v-model="selectedItem.responsible"
                  label="Responsable*"
                  classes="pt-0 input-custom-56"
                  placeholder=""
                  :disabled="disabledOptions"
                  :text-error="listError.responsible_id"
                  @update="onChangeResponsible"
                ></selector-user>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-stepper v-model="activeTab" class="custom-stepper elevation-0">
                <v-stepper-header>
                  <v-stepper-step
                    :complete="!isEmpty(selectedItem.stage_1.id)"
                    :color="
                      !isEmpty(selectedItem.stage_1.id) ? 'success' : 'primary'
                    "
                    :step="1"
                    :editable="isEditable"
                    :style="styleCursorStep(1)"
                    @click="onValidateStep(1)"
                  >
                    Etapa preliminar
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step
                    :complete="!isEmpty(selectedItem.stage_2.id)"
                    :color="
                      !isEmpty(selectedItem.stage_2.id) ? 'success' : 'primary'
                    "
                    :step="2"
                    :editable="isEditable"
                    :style="styleCursorStep(2)"
                    @click="onValidateStep(2)"
                  >
                    Etapa de Supervisión
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step
                    :complete="!isEmpty(selectedItem.stage_3.id)"
                    :color="
                      !isEmpty(selectedItem.stage_3.id) ? 'success' : 'primary'
                    "
                    :step="3"
                    :editable="isEditable"
                    :style="styleCursorStep(3)"
                    @click="onValidateStep(3)"
                  >
                    Etapa Sancionadora
                  </v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                  <v-stepper-content :step="1" class="pb-0">
                    <step-one
                      :id="selectedItem.id"
                      ref="stepOne"
                      v-model="selectedItem.stage_1"
                      :disabled-options="disabledEdit"
                      :type-dialog="typeDialog"
                      :type-module="typeModule"
                      :type-submodule="typeSubmodule"
                      @create="validateSubmitUpdate"
                      @next="nextStep"
                      @close="onCloseDialogUpdate"
                      @flag-edit="changeFlagQuestion($event, 'one')"
                      @flag-loading="changeLoadingSubmit"
                    ></step-one>
                  </v-stepper-content>
                  <v-stepper-content :step="2" class="pb-0">
                    <step-two
                      :id="selectedItem.id"
                      ref="stepTwo"
                      v-model="selectedItem.stage_2"
                      :disabled-options="disabledEdit"
                      :type-dialog="typeDialog"
                      :type-module="typeModule"
                      :type-submodule="typeSubmodule"
                      @create="validateSubmitUpdate"
                      @next="nextStep"
                      @close="onCloseDialogUpdate"
                      @flag-edit="changeFlagQuestion($event, 'two')"
                      @flag-loading="changeLoadingSubmit"
                    ></step-two>
                  </v-stepper-content>
                  <v-stepper-content :step="3" class="pb-0">
                    <step-three
                      :id="selectedItem.id"
                      ref="stepThree"
                      v-model="selectedItem.stage_3"
                      :disabled-options="disabledEdit"
                      :type-dialog="typeDialog"
                      :type-module="typeModule"
                      :type-submodule="typeSubmodule"
                      @create="validateSubmitUpdate"
                      @next="nextStep"
                      @close="onCloseDialogUpdate"
                      @flag-edit="changeFlagQuestion($event, 'three')"
                      @flag-loading="changeLoadingSubmit"
                    ></step-three>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-col>
          </v-row>
        </v-card-text>
      </vue-perfect-scrollbar>
      <v-card-actions
        v-show="!loadDetail"
        style="border-top: 1px solid rgba(0, 0, 0, 0.12);"
      >
        <template v-if="roleUser !== 'visualizer' && roleUser !== 'executor'">
          <v-btn
            v-if="typeDialog === 'edit' && !disabledEdit"
            color="primary"
            small
            depressed
            dark
            class="btn-transform-none"
            @click="disabledEdit = true"
            >Habilitar edición</v-btn
          >
        </template>
        <v-spacer></v-spacer>
        <v-btn
          color="action"
          class="btn-transform-none"
          text
          small
          @click="onCloseDialogUpdate()"
          >Cancelar</v-btn
        >
        <v-btn
          v-if="!isEmpty(selectedItem.id) && !selectedItem.finished"
          color="action"
          class="btn-transform-none"
          :disabled="disabledOptions"
          :dark="!disabledOptions"
          small
          depressed
          @click="dialogFinished = true"
          >Archivar</v-btn
        >
        <v-btn
          color="action"
          class="btn-transform-none"
          :disabled="disabledOptions"
          :dark="!disabledOptions"
          small
          depressed
          @click="validateSubmitUpdate()"
          ><v-progress-circular
            v-if="loadCreate"
            :size="22"
            class="d-inline-block mr-3"
            :width="2"
            indeterminate
            color="white"
          ></v-progress-circular>
          {{ loadCreate ? "Guardando" : "Guardar" }}
        </v-btn>
        <v-btn
          color="action"
          class="btn-transform-none"
          :disabled="disabledOptions"
          :dark="!disabledOptions"
          small
          depressed
          @click="validateSubmitUpdate(null, true)"
          ><v-progress-circular
            v-if="loadContinue"
            :size="22"
            class="d-inline-block mr-3"
            :width="2"
            indeterminate
            color="white"
          ></v-progress-circular>
          {{ loadContinue ? "Guardando" : "Guardar y continuar" }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="dialogFinished"
      max-width="400"
      @keydown.esc="dialogFinished = false"
    >
      <v-card>
        <v-card-text class="py-2">
          <p class="mb-0">
            ¿Estás seguro que deseas finalizar la supervisión
            <b>{{ selectedItem.id }}</b> ?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            class="btn-transform-none"
            :disabled="loadFinished"
            text
            small
            @click="dialogFinished = false"
            >Continuar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="btn-transform-none"
            text
            small
            :ripple="!loadFinished"
            @click="onSubmitFinished()"
          >
            <v-progress-circular
              v-if="loadFinished"
              :size="20"
              class="d-inline-block mr-3"
              :width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
            {{ loadFinished ? "Archivando" : "Archivar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialog-question
      v-model="dialogQuestion"
      @force-close="onCloseDialogQuestion"
    ></dialog-question>
  </v-dialog>
</template>
<script>
import { ISupervision } from "@/models";
import { mapState } from "vuex";
import { cloneDeep } from "lodash";
import mixinApiModule from "@/components/modules/mixins/mixinApiModule";
import DialogQuestion from "@/components/commons/DialogQuestion";
import mixinSteps from "./mixins/mixinSteps";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ExportWord from "@/components/commons/ExportWord";
import SelectorUser from "@/components/commons/SelectorUser";
import StepOne from "./steps/StepOne";
import StepTwo from "./steps/StepTwo";
import StepThree from "./steps/StepThree";

export default {
  name: "DialogDetailSupervision",
  components: {
    VuePerfectScrollbar,
    DialogQuestion,
    SelectorUser,
    ExportWord,
    StepOne,
    StepTwo,
    StepThree,
  },
  mixins: [mixinApiModule, mixinSteps],
  model: { prop: "dialogNew", event: "change" },
  props: {
    typeDialog: {
      type: String,
      default: "new",
    },
    typeModule: {
      type: String,
      default: "",
    },
    typeSubmodule: {
      type: String,
      default: "",
    },
    dialogNew: {
      type: Boolean,
      default: false,
    },
    selectedSpij: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    validUpdate: false,
    lazyUpdate: false,
    selectedSituation: null,
    selectedProject: null,
    selectedAuthority: null,
    selectedResponsible: null,
    disabledEdit: false,
    selectedItem: new ISupervision(),
    cloneItem: null,
    isDraft: false,
    dialogQuestion: false,
    loadingQuestion: false,
    dialogDetail: false,
    loadUpdate: false,
    loadCreate: false,
    loadDetail: false,
    // blocktop
    disabledBase: false,
    // finished
    loadFinished: false,
    dialogFinished: false,
    // rules
    rulesAuthority: [],
    rulesProject: [],
    rulesSituation: [],
    // delete
    deleteIndex: 0,
    dialogDelete: false,
    // export
    listError: {
      situation_id: "",
      project_id: "",
      responsible_id: "",
      authority_id: "",
    },
    loadContinue: false,
    isContinue: false,
    // editable
    flagEditForm: false,
    // flagSteps
    flagStepOne: false,
    flagStepTwo: false,
    flagStepThree: false,
  }),
  computed: {
    ...mapState({
      listMasters: (state) => state.masters.listMasters,
      listSituations: (state) => state.masters.listSituations,
      listProjects: (state) => state.masters.listProjects,
    }),
    disabledOptions() {
      if (this.typeDialog === "edit") {
        return !this.disabledEdit;
      } else {
        return false;
      }
    },
    listAuthority() {
      const auto = this.listMasters.filter((e) => {
        return e.slug === `${this.typeSubmodule}-autoridad`;
      });
      return auto.length ? auto[0] : { options: [] };
    },
  },
  methods: {
    onSubmitFinished() {
      if (this.loadFinished) return false;
      this.loadFinished = true;
      this.axios
        .post(
          `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.selectedItem.id}/finish`,
        )
        .then(() => {
          this.dialogFinished = false;
          this.loadFinished = false;
          this.onCloseDialogUpdate();
        });
    },
    async validateSubmitUpdate(step, isContinue = false) {
      this.isContinue = isContinue;
      if (this.selectedItem.id) {
        const cloneItem = cloneDeep(this.cloneItem);
        const cloneSelectedItem = cloneDeep(this.selectedItem);
        delete cloneSelectedItem.stage_1;
        delete cloneSelectedItem.stage_2;
        delete cloneSelectedItem.stage_3;
        delete cloneItem.stage_1;
        delete cloneItem.stage_2;
        delete cloneItem.stage_3;
        if (this.flagEditForm) {
          await this.onSubmitCreateBase("edit", step);
        } else {
          if (this.flagStepOne) {
            await this.$refs.stepOne.onSubmitStep(this.selectedItem.id);
          }
          if (this.flagStepTwo) {
            await this.$refs.stepTwo.onSubmitStep(this.selectedItem.id);
          }
          if (this.flagStepThree) {
            await this.$refs.stepThree.validateStepByStep();
          }
          if (!this.isContinue) {
            this.onCloseDialogUpdate();
          }
        }
      } else {
        await this.onSubmitCreateBase("new", step);
        this.disabledEdit = true;
      }
    },
    async onSubmitCreateBase(type = "new", step = null) {
      if (this.loadCreate) return false;
      if (this.loadContinue) return false;

      if (this.isContinue) {
        this.loadContinue = true;
      } else {
        this.loadCreate = true;
      }
      // this.loadUpdate = true;
      const payload = {
        situation_id: this.selectedSituation ? this.selectedSituation.id : null,
        authority_id: this.selectedAuthority ? this.selectedAuthority.id : null,
        responsible_id: this.selectedResponsible
          ? this.selectedResponsible.id
          : null,
        project_id: !this.isEmpty(this.selectedProject)
          ? this.selectedProject.id
          : null,
      };
      if (type === "new") {
        const { data } = await this.axios.post(
          `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/create`,
          payload,
        );
        if (data.success) {
          let resp = data.data;
          this.selectedItem.id = resp.id;
          this.$set(this.selectedItem, "id", resp.id);
          this.cloneItem = cloneDeep(resp);
          this.$toast.success(`La ficha ha sido creada correctamente.`);

          if (this.flagStepOne) {
            await this.$refs.stepOne.onSubmitStep();
          }
          if (this.flagStepTwo) {
            await this.$refs.stepTwo.onSubmitStep();
          }
          if (this.flagStepThree) {
            await this.$refs.stepThree.validateStepByStep();
          }
          if (this.isContinue) {
            this.loadContinue = false;
          } else {
            this.loadCreate = false;
          }
          this.flagEditForm = false;
          if (!this.isContinue) {
            this.onCloseDialogUpdate();
          }
        } else {
          if (this.isContinue) {
            this.loadContinue = false;
          } else {
            this.loadCreate = false;
          }
          this.listErrorSection(data.error.data);
        }
      } else {
        const { data } = this.axios.post(
          `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.selectedItem.id}/update`,
          payload,
        );

        if (data.success) {
          let resp = data.data;

          this.$toast.success(`La ficha ha sido actualizada correctamente.`);

          if (this.flagStepOne) {
            await this.$refs.stepOne.onSubmitStep();
          }
          if (this.flagStepTwo) {
            await this.$refs.stepTwo.onSubmitStep();
          }
          if (this.flagStepThree) {
            await this.$refs.stepThree.validateStepByStep();
          }

          if (this.isContinue) {
            this.loadContinue = false;
          } else {
            this.loadCreate = false;
          }
          this.flagEditForm = false;
        } else {
          if (this.isContinue) {
            this.loadContinue = false;
          } else {
            this.loadCreate = false;
          }
          this.listErrorSection(data.error.data);
        }
      }
    },
    listErrorSection(err) {
      const {
        situation_id = null,
        authority_id = null,
        responsible_id = null,
        project_id = null,
      } = err;
      // listError
      this.listError.situation_id = situation_id ? situation_id[0] : null;
      this.listError.authority_id = authority_id ? authority_id[0] : null;
      this.listError.responsible_id = responsible_id ? responsible_id[0] : null;
      this.listError.project_id = project_id ? project_id[0] : null;
      // rules
      this.rulesAuthority = this.listError.authority_id
        ? [() => this.listError.authority_id]
        : [];
      this.rulesProject = this.listError.project_id
        ? [() => this.listError.project_id]
        : [];
      this.rulesSituation = this.listError.situation_id
        ? [() => this.listError.situation_id]
        : [];

      this.$refs.formUpdate.validate();
    },
    onValidateStep(step) {
      switch (step) {
        case 1:
          this.activeTab = step;
          break;
        case 2:
          if (!this.isEmpty(this.selectedItem.stage_1.id))
            this.activeTab = step;
          break;
        case 3:
          if (!this.isEmpty(this.selectedItem.stage_2.id))
            this.activeTab = step;
          break;
        default:
          console.log(
            `Lo lamentamos, por el momento no disponemos de stage_${step}.`,
          );
      }
    },
    nextStep(step) {
      if (step === 2) {
        this.activeTab = 2;
      } else if (step === 3) {
        this.activeTab = 3;
        this.dialogUpdateActa = false;
      }
    },
    onCloseDialogQuestion() {
      this.dialogQuestion = false;
      this.$emit("change", false);
    },
    validateFormat(value, type) {
      if (type === "id") {
        return this.isEmpty(value) ? null : value.id;
      } else {
        return this.isEmpty(value) ? null : value;
      }
    },
    onCloseDialogUpdate() {
      const aux = cloneDeep(this.selectedItem);
      if (
        this.flagEditForm ||
        this.flagStepOne ||
        this.flagStepTwo ||
        this.flagStepThree
      ) {
        this.dialogQuestion = true;
      } else {
        this.$emit("change", false);
        this.dialogQuestion = false;
        this.selectedItem = new ISupervision();
        this.isContinue = false;
        this.$refs.stepOne.clearForm();
        this.$refs.stepTwo.clearForm();
        this.$refs.stepThree.clearForm();
        this.$emit("close");
      }
      if (this.typeDialog === "new" || this.typeDialog === "add") {
        this.$emit("create-active", aux);
      }
    },
    openDialogDetail(item, flagEdit = false) {
      this.activeTab = 1;
      this.selectedAuthority = null;
      this.selectedResponsible = null;
      this.selectedSituation = null;
      this.selectedProject = null;
      this.selectedItem = new ISupervision();
      this.cloneItem = new ISupervision();
      this.$nextTick(() => {
        if (flagEdit) this.disabledEdit = flagEdit;
      });
      if (this.$refs.stepOne) {
        this.$refs.stepOne.clearForm();
      }
      if (this.$refs.stepTwo) {
        this.$refs.stepTwo.clearForm();
      }
      if (this.$refs.stepThree) {
        this.$refs.stepThree.clearForm();
      }
    },
    async openDialogEdit(item) {
      this.activeTab = 1;
      this.loadDetail = true;
      const { data } = await this.axios.get(
        `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${item.id}`,
      );
      if (data.success) {
        this.flagEditForm = false;
        const selectedItem = cloneDeep(data.data);
        if (this.isEmpty(selectedItem.stage_1)) {
          selectedItem.stage_1 = { id: null };
        } else this.activeTab = 1;
        if (this.isEmpty(selectedItem.stage_2)) {
          selectedItem.stage_2 = { id: null };
        } else this.activeTab = 2;
        if (this.isEmpty(selectedItem.stage_3)) {
          selectedItem.stage_3 = { id: null };
        } else this.activeTab = 3;
        this.selectedItem = cloneDeep(selectedItem);
        this.cloneItem = cloneDeep(selectedItem);
        this.setItems(selectedItem);
        this.disabledEdit = false;
        this.loadDetail = false;
        this.$nextTick(() => {
          if (this.$refs.stepOne) {
            this.$refs.stepOne.setForm();
          }
          if (this.$refs.stepTwo) {
            this.$refs.stepTwo.setForm();
          }
          if (this.$refs.stepThree) {
            this.$refs.stepThree.setForm();
          }
        });
      }
    },
    setItems(selectedItem) {
      this.selectedAuthority = selectedItem.authority;
      this.selectedResponsible = selectedItem.responsible;
      this.selectedSituation = selectedItem.situation;
      this.selectedProject = selectedItem.project;
    },
    clearErrors() {
      // set errror
      this.$nextTick(() => {
        this.listError = {
          situation_id: "",
          project_id: "",
          responsible_id: "",
          authority_id: "",
        };
      });
    },
    onChangeAuthority(authority) {
      this.flagEditForm = true;
      this.listError.authority_id = null;
      this.selectedAuthority = authority;
    },
    onChangeResponsible(responsible) {
      this.flagEditForm = true;
      this.listError.responsible_id = null;
      this.selectedResponsible = responsible;
    },
    onChangeSituation(situation) {
      this.flagEditForm = true;
      this.listError.situation_id = null;
      this.selectedSituation = situation;
    },
    onChangeProject(project) {
      this.flagEditForm = true;
      this.listError.project_id = null;
      this.selectedProject = project;
    },
    changeLoadingSubmit(flag) {
      if (this.isContinue) {
        this.loadContinue = flag;
      } else {
        this.loadCreate = flag;
      }
    },
    changeFlagQuestion(flag, step) {
      // console.log(flag, step);
      if (step === "one") {
        this.flagStepOne = flag;
      } else if (step === "two") {
        this.flagStepTwo = flag;
      } else if (step === "three") {
        this.flagStepThree = flag;
      }
      // this.flagEditForm = this.flagEditForm ? true : flag;
    },
    styleCursorStep(step = 1) {
      let flag = !this.isEmpty(this.selectedItem[`stage_${step}`].id);
      return `cursor: ${flag ? "pointer" : "initial"}`;
    },
  },
};
</script>
