import { render, staticRenderFns } from "./ContractsSection.vue?vue&type=template&id=fa9d230e"
import script from "./ContractsSection.vue?vue&type=script&lang=js"
export * from "./ContractsSection.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports