<template>
  <div>
    <v-btn
      class="btn-transform-none"
      depressed
      small
      :ripple="!loadExcel"
      @click="onSubmitExcel()"
    >
      <v-progress-circular
        v-if="loadExcel"
        :size="20"
        class="d-inline-block mr-3"
        :width="2"
        indeterminate
        color="black"
      ></v-progress-circular>
      {{ loadExcel ? "Exportando" : "Exportar" }}
    </v-btn>
    <a id="urlDownload" :href="urlDownload" class="d-none" target="_blank"
      >download...</a
    >
  </div>
</template>
<script>
import {v4} from "uuid";

export default {

  name: "ExportExcel",
  props: {
    filterData: { type: String, default: "" },
    sortItems: { type: String, default: "" },
    apiModule: { type: Object, default: () => {} },
  },
  data: () => ({
    urlDownload: "",
    loadExcel: false,
  }),
  methods: {
    async onSubmitExcel() {
      if (this.loadExcel) return false;
      this.loadExcel = true;
      const filterExcel = this.filterData.slice(1, this.filterData.length);
      let urlSort = "";
      if (this.sortItems) {
        urlSort = `&sort=${this.sortItems}`;
      }
      this.axios(
        {
          url: `/api/${this.apiModule.module}/${this.apiModule.submodule}/export/excel?${filterExcel}${urlSort}`,
          responseType: 'arraybuffer',
        }
      ).then(({ data }) => {
        let blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })

        let file = `${v4()}.xlsx`;

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = file;
        link.click();

        this.$toast.success(`El archivo ${file} se ha descargado correctamente.`)

        this.loadExcel = false;
      });
    },
  },
};
</script>
