export const MenuAdmin = [
  {
    icon: "icon-modulos.png",
    label: "Módulos",
    key: "modules",
    leaf: false,
    submenu: [
      {
        icon: "permisos2",
        title: "Permisos",
        key: "permisos",
        leaf: false,
        submenu: [],
      },
      {
        icon: "contratos",
        title: "Contratos",
        key: "contratos",
        leaf: false,
        submenu: [],
      },
      {
        icon: "compromiso",
        title: "Compromisos",
        key: "compromisos",
        leaf: false,
        submenu: [],
      },
      {
        icon: "seguridad-salud",
        title: "Seguridad y salud ocupacional",
        key: "seguridad-salud",
        leaf: false,
        submenu: [],
      },
      {
        icon: "ambiental",
        title: "Ambiental",
        key: "ambiental",
        leaf: false,
        submenu: [],
      },
      {
        icon: "supervisiones",
        name: "SurveillancePage",
        title: "Supervisiones",
        key: "superviciones",
        leaf: false,
        submenu: [],
      },
      {
        icon: "contratos",
        title: "Procesos Judiciales",
        name: "ProcessPage",
        key: "procesos",
        leaf: true,
        submenu: [],
      },
    ],
  },
  {
    icon: "icon-to-dos.png",
    name: "TodoPage",
    label: "To-Do",
    key: "to-do",
    leaf: false,
    submenu: [],
  },
  {
    icon: "icon-reportes.png",
    name: "ReportPage",
    label: "Reportes",
    key: "reportes",
    leaf: false,
    submenu: [],
  },
  {
    icon: "icon-mapas.png",
    label: "Mapas",
    name: "MapPage",
    key: "mapa",
    leaf: true,
    submenu: [],
  },
  {
    icon: "icon-usuarios.png",
    label: "Usuarios",
    name: "UserPage",
    key: "usuarios",
    leaf: true,
    submenu: [],
  },
  {
    icon: "icon-ajustes.png",
    name: "SettingsPage",
    label: "Ajustes",
    key: "ajustes",
    leaf: false,
    submenu: [
      {
        icon: "icon-copyright.png",
        label: "Copy",
        name: "CopyPage",
        key: "copy",
        leaf: false,
        submenu: [],
      },
      {
        icon: "icon-terminos-condiciones.png",
        label: "Términos y condiciones",
        name: "TermPage",
        key: "terminos-condiciones",
        leaf: false,
        submenu: [],
      },
      {
        icon: "icon-politicas-privacidad.png",
        label: "Política y privacidad",
        name: "PoliticPage",
        key: "politica-privacidad",
        leaf: false,
        submenu: [],
      },
      {
        icon: "icon-ajustes.png",
        label: "Ajustes generales",
        name: "NotificationPage",
        key: "ajustes-generales",
        leaf: false,
        submenu: [],
      },
      {
        icon: "icon-maestros.png",
        label: "Maestros",
        name: "MastersPage",
        key: "maestro",
        leaf: false,
        submenu: [],
      },
    ],
  },
];
