<template>
  <v-container id="pageDashboard" fluid class="page-dashboard pa-0 fill-height">
    <v-card class="card-toolbar" min-width="100%" flat tile>
      <v-toolbar flat>
        <v-toolbar-title
          >Hola,
          <template v-if="currentUser">{{
            `${currentUser.first_name} ${currentUser.last_name}`
          }}</template
          ></v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>
    <template v-if="!isMobile">
      <vue-perfect-scrollbar
        class="card-menu--scroll"
        style="width: 100%;"
        :settings="scrollSettings"
      >
        <v-container fluid fill-height style="height: calc(100% - 65px);">
          <v-row align="center" justify="center">
            <v-col cols="12" lg="10" class="py-0">
              <v-row justify="center">
                <template v-for="(item, index) in listItem">
                  <v-col
                    v-if="validateRole(item)"
                    :key="index"
                    cols="12"
                    lg="4"
                    md="4"
                    sm="6"
                    class="pa-2"
                  >
                    <card-menu
                      :id="item.id"
                      :key="`cardMenu${item.id}`"
                      :item="item"
                      @click.native="goToMenu(item, 0, index)"
                    ></card-menu>
                  </v-col>
                </template>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </vue-perfect-scrollbar>
    </template>
    <v-container v-if="isMobile" fluid class="pt-5">
      <v-row>
        <v-col cols="12" class="pa-2">
          <card-menu
            :id="onlyTodo.id"
            :key="`cardMenu${onlyTodo.id}`"
            :item="onlyTodo"
            @click.native="goToMenu(onlyTodo, 0, 1)"
          ></card-menu>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions } from "vuex";
import { MenuAdmin } from "@/api/menu";
import CardMenu from "@/components/commons/CardMenu";
import { isMobile } from "mobile-device-detect";

const urlsSimple = [
  "ReportPage",
  "TodoPage",
  "UserPage",
  "MapPage",
  "SettingsPage",
];

export default {
  name: "DashboardPage",
  components: {
    VuePerfectScrollbar,
    CardMenu,
  },
  data: () => ({
    isMobile,
    scrollSettings: {
      maxScrollbarLength: 100,
      suppressScrollX: true,
    },
    listItem: MenuAdmin,
    onlyTodo: MenuAdmin[1],
  }),
  created() {
    this.$store.dispatch(`menuAdmin/setMenuInit`, []);
    this.$store.dispatch(`menuAdmin/setListActive`, {
      active: null,
      init: true,
      index: 0,
    });
    this.$store.dispatch("menuAdmin/setMenuActive", {});
    this.clearActiveModule("all");
  },
  methods: {
    ...mapActions(["clearActiveModule"]),
    validateRole(item) {
      if (this.roleUser === "admin" && item.key === "usuarios") {
        return false;
      } else if (
        this.roleUser === "visualizer" &&
        item.key !== "modules" &&
        item.key !== "reportes" &&
        item.key !== "to-do" &&
        item.key !== "mapa"
      ) {
        return false;
      } else if (this.roleUser === "executor" && item.key !== "to-do") {
        return false;
      }
      return true;
    },
    goToMenu(menu) {
      this.$store.dispatch(`menuAdmin/setMenu`, {
        menu: this.listItem,
        init: true,
        index: 0,
      });
      this.$store.dispatch(`menuAdmin/setMenuActive`, menu);
      this.$store.dispatch(`menuAdmin/setListActive`, {
        active: menu,
        init: true,
        index: 0,
      });
      if (menu.key === "modules") {
        this.$router.push({ name: "ModulesPage" });
      } else {
        if (urlsSimple.includes(menu.name)) {
          this.$router.push({ name: menu.name });
        } else {
          this.$router.push({
            name: "Level1MenuPage",
            params: { id: menu.key },
          });
        }
      }
    },
  },
};
</script>
