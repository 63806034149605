<template>
  <v-dialog v-model="dialogNew" content-class="dialog-custom" persistent open-on-focus scrollable max-width="950px"
    @keydown.esc="onCloseDialogUpdate()">
    <v-card>
      <v-card-title class="py-2">
        <span class="headline">{{
          typeDialog === "new" ? "Nuevo Proceso Judicial" : "Proceso Judicial"
        }}</span>
        <v-spacer></v-spacer>
        <export-word v-if="typeDialog === 'edit' && !loadDetail" :id="selectedItem.id"
          :api-module="apiByModule"></export-word>
        <v-btn slot="activator" class="m-0" icon fab small @click="onCloseDialogUpdate()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-col v-show="loadDetail" cols="12">
        <v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
      </v-col>
      <vue-perfect-scrollbar v-show="!loadDetail" class="drawer-menu--scroll" :settings="scrollSettings"
        :swicher="!loadDetail">
        <v-card-text class="pb-0">
          <v-form ref="formUpdate" v-model="validUpdate" :lazy-validation="lazyUpdate">
            <v-row>
              <v-col v-if="typeDialog !== 'new' && selectedItem && selectedItem.id" cols="4" class="py-0">
                <v-text-field v-model="selectedItem.id" disabled outlined label="Código" height="56" hide-details
                  class="pt-0 input-custom-56"></v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-text-field v-model="selectedItem.file_code" class="pt-0 mb-3 input-custom-56"
                  label="Código de expediente" height="56" outlined :hide-details="isEmpty(listError.file_code)"
                            :rules="rulesFileCode" :readonly="disabledOptions" @input="onChangeFileCode" ></v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-text-field v-model="selectedItem.respondent_party" class="pt-0 mb-3 input-custom-56"
                  label="Parte demandada*" height="56" outlined :hide-details="isEmpty(listError.respondent_party)"
                  :rules="rulesRespondentParty" :disabled="disabledOptions"
                  @input="onChangeRespondentParty"></v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-text-field v-model="selectedItem.complaining_party" class="pt-0 mb-3 input-custom-56"
                  label="Parte demandante*" height="56" outlined :hide-details="isEmpty(listError.complaining_party)"
                  :rules="rulesComplainingParty" :disabled="disabledOptions"
                  @input="onChangeComplainingParty"></v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-autocomplete v-model="selectedItem.project" class="pt-0 input-custom-56" label="Proyecto*"
                  item-text="label" item-value="id" clearable outlined height="56" return-object
                  :hide-details="isEmpty(listError.project_id)" :rules="rulesProject" :items="listProjects.options"
                  :disabled="disabledOptions" @input="onChangeProject"></v-autocomplete>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-autocomplete v-model="selectedItem.cargo_study" class="pt-0 input-custom-56" label="Estudio de cargo*"
                  item-text="label" item-value="id" height="56" outlined clearable return-object
                  :hide-details="isEmpty(listError.cargo_study_id)" :rules="rulesCargoStudy"
                  :items="listCargoStudies.options" :disabled="disabledOptions"
                  @input="onChangeCargoStudy"></v-autocomplete>
              </v-col>
              <v-col cols="4" class="py-0">
                <selector-user ref="responsibleMain" v-model="selectedItem.responsible" label="Responsable*"
                  classes="pt-0 input-custom-56" placeholder="" :disabled="disabledOptions"
                  :text-error="listError.responsible_id" @update="onChangeResponsible"></selector-user>
              </v-col>
              <v-col cols="4" class="py-0">
                <selector-user ref="responsibleSecondary" v-model="selectedItem.secondary_responsible" label="Responsable secundario"
                               classes="pt-0 input-custom-56" placeholder="" :disabled="disabledOptions"
                               :text-error="listError.responsible_secondary_id" @update="onChangeSecondaryResponsible"></selector-user>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-text-field v-model="selectedItem.controversial_matter" class="pt-0 mb-3 input-custom-56"
                  label="Materia controvertida*" height="56" outlined :readonly="disabledOptions"
                  :hide-details="isEmpty(listError.controversial_matter)" :rules="rulesControversialMatter" @input="onChangeControversialMatter"></v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-text-field v-model="selectedItem.amount" class="pt-0 mb-3 input-custom-56" label="Cuantía*" height="56"
                  outlined hint="S/., US$ o Indeterminado" persistent-hint :rules="rulesAmount"
                  :readonly="disabledOptions" @input="onChangeAmount"></v-text-field>
              </v-col>
            </v-row>
          </v-form>

          <v-row v-if="selectedItem.id">

            <v-col v-if="!isEmpty(selectedItem.id)" cols="12" class="pb-0">
              <v-alert v-if="actuaciones" prominent dense :type="actuaciones.Nuevos ? 'info' : 'warning'" style="padding:1rem 1rem 1rem 1rem;">
                <v-row align="center">
                  <v-col class="grow">
                    {{ actuaciones.Mensaje }}
                  </v-col>
                  <v-col class="shrink">
                    <v-btn @click="onClickActuaciones" depressed :color="actuaciones.Nuevos ? 'action' : 'warning'" class="btn-transform-none" dark small>
                        Ver detalle
                    </v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>

            <v-col cols="12" class="pb-0">
              <v-stepper v-model="activeTab" class="custom-stepper elevation-0">
                <v-stepper-header>
                  <v-stepper-step :complete="!isEmpty(selectedItem.stage_1.id)" :step="1" :color="!isEmpty(selectedItem.stage_1.id) ? 'success' : 'primary'
                    " :editable="isEditable" :style="styleCursorStep(1)"  @click="onValidateStep(1)" >
                    Primera Instancia
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="!isEmpty(selectedItem.stage_2.id)" :color="!isEmpty(selectedItem.stage_2.id) ? 'success' : 'primary'
                    " :step="2" :editable="isEditable" :style="styleCursorStep(2)" @click="onValidateStep(2)">
                    Segunda Instancia
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="!isEmpty(selectedItem.stage_3.id)" :color="!isEmpty(selectedItem.stage_3.id) ? 'success' : 'primary'
                    " :step="3" :editable="isEditable" :style="styleCursorStep(3)" @click="onValidateStep(3)">
                    Casación
                  </v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                  <v-stepper-content :step="1">
                    <!-- Primera instancia -->
                    <common-step-one :id="selectedItem.id" ref="stepOne" v-model="selectedItem.stage_1"
                      :disabled-options="disabledOptions" :type-dialog="typeDialog" :type-module="typeModule"
                      :type-submodule="typeSubmodule" @create="validateSubmitUpdate" @next="nextStep"
                      @close="onCloseDialogUpdate" @flag-edit="changeFlagQuestion($event, 'one')"
                      @flag-loading="changeLoadingSubmit" :fileCode="selectedItem.file_code">
                    </common-step-one>

                  </v-stepper-content>
                  <!-- Segunda instancia -->
                  <v-stepper-content :step="2">
                    <common-step-two :fileCode="selectedItem?.stage_2?.file_code" :id="selectedItem.id" ref="stepTwo" v-model="selectedItem.stage_2"
                      :disabled-options="disabledOptions" :type-dialog="typeDialog" :type-module="typeModule"
                      :type-submodule="typeSubmodule" @create="validateSubmitUpdate" @next="nextStep"
                      @close="onCloseDialogUpdate" @flag-edit="changeFlagQuestion($event, 'two')"
                      @flag-loading="changeLoadingSubmit"></common-step-two>
                  </v-stepper-content>
                  <!-- Tercera instancia -->
                  <v-stepper-content :step="3">
                    <common-step-three  :fileCode="selectedItem?.stage_3?.file_code" :id="selectedItem.id" ref="stepThree" v-model="selectedItem.stage_3"
                      :disabled-options="disabledOptions" :type-dialog="typeDialog" :type-module="typeModule"
                      :type-submodule="typeSubmodule" @create="validateSubmitUpdate" @next="nextStep"
                      @close="onCloseDialogUpdate" @flag-edit="changeFlagQuestion($event, 'three')"
                      @flag-loading="changeLoadingSubmit"></common-step-three>
                  </v-stepper-content>

                </v-stepper-items>
              </v-stepper>
            </v-col>
          </v-row>
        </v-card-text>
      </vue-perfect-scrollbar>
      <v-card-actions v-show="!loadDetail" style="border-top: 1px solid rgba(0, 0, 0, 0.12)">
        <template v-if="roleUser !== 'visualizer' && roleUser !== 'executor'">
          <v-btn v-if="typeDialog === 'edit' && !disabledEdit" color="primary" small depressed dark
            class="btn-transform-none" @click="disabledEdit = true">Habilitar edición</v-btn>
        </template>
        <v-spacer></v-spacer>
        <v-btn color="action" class="btn-transform-none" text small @click="onCloseDialogUpdate()">Cancelar</v-btn>
        <!-- <v-btn v-if="!isEmpty(selectedItem.id) && !selectedItem.finished" color="action" class="btn-transform-none"
          :disabled="disabledOptions" :dark="!disabledOptions" small depressed
          @click="dialogFinished = true">Archivar</v-btn>
        <v-btn color="action" class="btn-transform-none" :disabled="disabledOptions" :dark="!disabledOptions" small
          depressed @click="validateSubmitUpdate()"><v-progress-circular v-if="loadCreate" :size="22"
            class="d-inline-block mr-3" :width="2" indeterminate color="white"></v-progress-circular>
          {{ loadCreate ? "Guardando" : "Guardar" }}
        </v-btn> -->

        <v-btn color="action" class="btn-transform-none" :disabled="disabledOptions" :dark="!disabledOptions" small
          depressed @click="validateSubmitUpdate(null, true)"><v-progress-circular v-if="loadContinue" :size="22"
            class="d-inline-block mr-3" :width="2" indeterminate color="white"></v-progress-circular>
          {{ loadContinue ? "Guardando" : "Guardar y continuar" }}
        </v-btn>

      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialogFinished" max-width="400" @keydown.esc="dialogFinished = false">
      <v-card>
        <v-card-text class="py-2">
          <p class="mb-0">
            ¿Estás seguro que deseas finalizar la supervisión
            <b>{{ selectedItem.id }}</b> ?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" class="btn-transform-none" :disabled="loadFinished" text small
            @click="dialogFinished = false">Continuar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="btn-transform-none" text small :ripple="!loadFinished"
            @click="onSubmitFinished()">
            <v-progress-circular v-if="loadFinished" :size="20" class="d-inline-block mr-3" :width="2" indeterminate
              color="primary"></v-progress-circular>
            {{ loadFinished ? "Archivando" : "Archivar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialog-question v-model="dialogQuestion" @force-close="onCloseDialogQuestion"></dialog-question>
  </v-dialog>
</template>
<script>
import { IJudicialProcess } from "@/models";
import { mapState } from "vuex";
import { cloneDeep } from "lodash";
import mixinApiModule from "@/components/modules/mixins/mixinApiModule";
import DialogQuestion from "@/components/commons/DialogQuestion";
import mixinSteps from "./mixins/mixinSteps";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ExportWord from "@/components/commons/ExportWord";
import SelectorUser from "@/components/commons/SelectorUser";
/*Prima instancia */
import CommonStepOne from "./steps/CommonStepOne";
import CommonStepTwo from "./steps/CommonStepTwo";
import CommonStepThree from "./steps/CommonStepThree";

export default {
  name: "DialogDetailJudicialProcess",
  components: {
    VuePerfectScrollbar,
    DialogQuestion,
    SelectorUser,
    ExportWord,
    CommonStepOne,
    CommonStepTwo,
    CommonStepThree,
  },
  mixins: [mixinApiModule, mixinSteps],
  // model: { prop: "dialogNew", event: "change" },
  props: {
    typeDialog: {
      type: String,
      default: "new",
    },
    typeModule: {
      type: String,
      default: "",
    },
    typeSubmodule: {
      type: String,
      default: "",
    },
    dialogNew: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    actuaciones: null,
    validUpdate: false,
    lazyUpdate: false,
    inputFileCode: null,
    inputRespondentParty: null,
    inputComplainingParty: null,
    inputControversialMatter: null,
    inputAmount: null,
    selectedCargoStudy: null,
    selectedProject: null,
    selectedResponsible: null,
    selectedSecondaryResponsible: null,
    disabledEdit: false,
    selectedItem: new IJudicialProcess(),
    cloneItem: null,
    isDraft: false,
    dialogQuestion: false,
    loadingQuestion: false,
    dialogDetail: false,
    loadUpdate: false,
    loadCreate: false,
    loadDetail: false,
    // finished
    loadFinished: false,
    dialogFinished: false,
    // rules
    rulesFileCode: [],
    rulesProject: [],
    rulesCargoStudy: [],
    rulesRespondentParty: [],
    rulesComplainingParty: [],
    rulesControversialMatter: [],
    rulesAmount: [],
    // delete
    deleteIndex: 0,
    dialogDelete: false,
    // export
    listError: {
      respondent_party: "",
      complaining_party: "",
      controversial_matter: "",
      amount: "",
      file_code: "",
      project_id: "",
      cargo_study_id: "",
      responsible_id: "",
      responsible_secondary_id: "",
    },
    loadContinue: false,
    isContinue: false,
    // editable
    flagEditForm: false,
    // flagSteps
    flagStepOne: false,
    flagStepTwo: false,
    flagStepThree: false,
    currentDate: null,
  }),
  mounted() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    this.currentDate = `${day}/${month}/${year}`;
  },
  computed: {
    // courts
    ...mapState({
      listMasters: (state) => state.masters.listMasters,
      listSituations: (state) => state.masters.listSituations,
      listProjects: (state) => state.masters.listProjects,
    }),

    disabledOptions() {
      if (this.typeDialog === "edit") {
        return !this.disabledEdit;
      } else {
        return false;
      }
    },

    listCargoStudies() {
      const auto = this.listMasters.filter((e) => {
        return e.slug === "cargo-studies";
      });
      return auto.length ? auto[0] : { options: [] };
    },
  },

  methods: {
    onClickActuaciones() {
      window.open(this.actuaciones.URL, "_blank");
    },
        onSubmitFinished() {
      if (this.loadFinished) return false;
      this.loadFinished = true;
      this.axios
        .post(
          `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.selectedItem.id}/finish`,
        )
        .then(() => {
          this.loadFinished = false;
        });
    },
    /* Se ejecuta cuando damos click en guardar */
    /* en la siguiente funcion se activan los flags: changeFlagQuestion */
    async validateSubmitUpdate(step, isContinue = false) {

      this.isContinue = isContinue;
      if (this.selectedItem.id) {
        const cloneItem = cloneDeep(this.cloneItem);
        const cloneSelectedItem = cloneDeep(this.selectedItem);
        delete cloneSelectedItem.stage_1;
        delete cloneSelectedItem.stage_2;
        delete cloneSelectedItem.stage_3;
        delete cloneItem.stage_1;
        delete cloneItem.stage_2;
        delete cloneItem.stage_3;

        if (this.flagEditForm) {
          await this.onSubmitCreateBase("edit");
        } else {
          ///Se ejecuta solo en el paso uno
          if (this.flagStepOne) {
            //Esta funcion se encuentra en el mixinInstancia.js
            //Nos vamos por el modo dinamico
            //this.flagStepOne.posicion si llega menor a 0 entonces quiere decir que estamos en una instancia

            if (this.flagStepOne.posicion == -1) {
              await this.$refs.stepOne.validateStepByStep();
            } else {
              await this.$refs.stepOne.validateStepByStepDinamicamente(
                this.flagStepOne.posicion,
              );
            }
          }

          if (this.flagStepTwo) {
            console.log(4);
            ///Se ejecuta solo en el paso uno
            if (this.flagStepTwo.posicion == -1) {
              console.log(22);
              await this.$refs.stepTwo.validateStepByStep();
            } else {
              console.log(11);
              await this.$refs.stepTwo.validateStepByStepDinamicamente(
                this.flagStepTwo.posicion,
              );
            }
          }
          if (this.flagStepThree) {
            if (this.flagStepThree.posicion == -1) {
              await this.$refs.stepThree.validateStepByStep();
            } else {
              await this.$refs.stepThree.validateStepByStepDinamicamente(
                this.flagStepThree.posicion,
              );
            }
          }
        }
      } else {
        //Crea la base
        await this.onSubmitCreateBase("new");
      }
    },
    /*Primera ejecucion base */
    async onSubmitCreateBase(type = "new") {
      if (this.loadCreate) return false;
      if (this.loadContinue) return false;

      if (this.isContinue) {
        this.loadContinue = true;
      } else {
        this.loadCreate = true;
      }
      const payload = {
        file_code: this.inputFileCode ? this.inputFileCode : null,
        amount: this.inputAmount ? this.inputAmount : null,
        controversial_matter: this.inputControversialMatter
          ? this.inputControversialMatter
          : null,
        complaining_party: this.inputComplainingParty
          ? this.inputComplainingParty
          : null,
        respondent_party: this.inputRespondentParty
          ? this.inputRespondentParty
          : null,
        cargo_study_id: this.selectedCargoStudy
          ? this.selectedCargoStudy.id
          : null,
        responsible_id: this.selectedResponsible
          ? this.selectedResponsible.id
          : null,
        responsible_secondary_id: this.selectedSecondaryResponsible
          ? this.selectedSecondaryResponsible.id
          : null,
        project_id: !this.isEmpty(this.selectedProject)
          ? this.selectedProject.id
          : null,
      };
      if (type === "new") {
        const { data } = await this.axios.post(
          `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/create`,
          payload,
        );
        if (data.success) {
          this.$toast.success(`El proceso judicial con código ${data.data.file_code}, ha sido creado correctamente`);

          let resp = data.data;
          this.loadContinue = false;
          this.selectedItem.id = resp.id;
          this.$set(this.selectedItem, "id", resp.id);
          this.cloneItem = cloneDeep(resp);
          if (this.flagStepOne) {
            await this.$refs.stepOne.validateStepByStep();
          }
          if (this.flagStepTwo) {
            await this.$refs.stepTwo.validateStepByStep();
          }
          if (this.flagStepThree) {
            await this.$refs.stepThree.validateStepByStep();
          }
          if (this.isContinue) {
            this.loadContinue = false;
          } else {
            this.loadCreate = false;
          }
          this.flagEditForm = false;
          if (!this.isContinue) {
            this.onCloseDialogUpdate();
          }
        } else {
          if (this.isContinue) {
            this.loadContinue = false;
          } else {
            this.loadCreate = false;
          }
          this.listErrorSection(data.error.data);
        }
      } else {
        const { data } = await this.axios.post(
          `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${this.selectedItem.id}/update`,
          payload,
        );
        if (data.success) {
          this.$toast.success(`El proceso judicial con el expediente ${data.data.file_code}, se ha actualizado correctamente!`);
          let resp = data.data;
          if (this.flagStepOne) {
            await this.$refs.stepOne.validateStepByStep(resp.id);
          }
          if (this.flagStepTwo) {
            await this.$refs.stepTwo.validateStepByStep(resp.id);
          }
          if (this.flagStepThree) {
            await this.$refs.stepThree.validateStepByStep(resp.id);
          }
          if (this.isContinue) {
            this.loadContinue = false;
          } else {
            this.loadCreate = false;
          }
          this.flagEditForm = false;
        } else {
          if (this.isContinue) {
            this.loadContinue = false;
          } else {
            this.loadCreate = false;
          }
          this.listErrorSection(data.error.data);
        }
      }
    },

    listErrorSection(err) {
      const {
        respondent_party = "",
        complaining_party = "",
        controversial_matter = "",
        amount = "",
        file_code = "",
        project_id = "",
        cargo_study_id = "",
        responsible_id = "",
        responsible_secondary_id = "",
      } = err;
      // listError
      this.listError.respondent_party = respondent_party
        ? respondent_party[0]
        : null;
      this.listError.complaining_party = complaining_party
        ? complaining_party[0]
        : null;
      this.listError.controversial_matter = controversial_matter
        ? controversial_matter[0]
        : null;
      this.listError.amount = amount ? amount[0] : null;
      this.listError.cargo_study_id = cargo_study_id ? cargo_study_id[0] : null;
      this.listError.file_code = file_code ? file_code[0] : null;
      this.listError.responsible_id = responsible_id ? responsible_id[0] : null;
      this.listError.responsible_secondary_id = responsible_secondary_id ? responsible_secondary_id[0] : null;
      this.listError.project_id = project_id ? project_id[0] : null;
      // rules
      this.rulesControversialMatter = this.listError.controversial_matter
        ? [() => this.listError.controversial_matter]
        : [];
      this.rulesAmount = this.listError.amount
        ? [() => this.listError.amount]
        : [];
      this.rulesRespondentParty = this.listError.respondent_party
        ? [() => this.listError.respondent_party]
        : [];
      this.rulesComplainingParty = this.listError.complaining_party
        ? [() => this.listError.complaining_party]
        : [];
      this.rulesCargoStudy = this.listError.cargo_study_id
        ? [() => this.listError.cargo_study_id]
        : [];
      this.rulesFileCode = this.listError.file_code
        ? [() => this.listError.file_code]
        : [];
      this.rulesProject = this.listError.project_id
        ? [() => this.listError.project_id]
        : [];
      this.$refs.formUpdate.validate();
    },

    onValidateStep(step) {
      switch (step) {
        case 1:
          this.activeTab = step;
          break;
        case 2:
          if (!this.isEmpty(this.selectedItem.stage_1.id))
            this.activeTab = step;
          break;
        case 3:
          if (!this.isEmpty(this.selectedItem.stage_2.id))
            this.activeTab = step;
          break;
        default:
          console.log(
            `Lo lamentamos, por el momento no disponemos de stage_${step}.`,
          );
      }
    },

    nextStep(step) {
      if (step === 2) {
        this.activeTab = 2;
      } else if (step === 3) {
        this.activeTab = 3;
        this.loadSave = true;
      }
    },

    onCloseDialogQuestion() {
      this.dialogQuestion = false;
      this.$emit("close");
    },

    validateFormat(value, type) {
      if (type === "id") {
        return this.isEmpty(value) ? null : value.id;
      } else {
        return this.isEmpty(value) ? null : value;
      }
    },
    //cerrar modal | bjar
    onCloseDialogUpdate() {
      const aux = cloneDeep(this.selectedItem);
      if (
        this.flagEditForm ||
        this.flagStepOne ||
        this.flagStepTwo ||
        this.flagStepThree
      ) {
        this.dialogQuestion = true;
      } else {
        this.$emit("change", false);
        this.dialogQuestion = false;
        this.selectedItem = new IJudicialProcess();
        this.isContinue = false;
        this.$emit("close");
      }
      if(this.selectedItem.id){
        this.$refs.stepOne.clearForm();
        this.$refs.stepTwo.clearForm();
        this.$refs.stepThree.clearForm();
      }


      if (this.typeDialog === "new" || this.typeDialog === "add") {
        this.$emit("create-active", aux);
      }
    },

    openDialogDetail(item, flagEdit = false) {
      this.activeTab = 1;
      this.clearErrors();
      this.selectedItem = new IJudicialProcess();
      this.cloneItem = new IJudicialProcess();
      this.$nextTick(() => {
        if (flagEdit) this.disabledEdit = flagEdit;
      });
      if (this.$refs.stepOne) {
        this.$refs.stepOne.clearForm();
      }
      if (this.$refs.stepTwo) {
        this.$refs.stepTwo.clearForm();
      }
      if (this.$refs.stepThree) {
        this.$refs.stepThree.clearForm();
      }
    },

    async openDialogEdit(item) {
      this.activeTab = 1;
      this.loadDetail = true;
      const { data } = await this.axios.get(
        `/api/${this.apiByModule.module}/${this.apiByModule.submodule}/${item.id}`,
      );
      if (data.success) {
        this.flagEditForm = false;

        const selectedItem = cloneDeep(data.data);

        if (this.isEmpty(selectedItem.stage_1)) {
          selectedItem.stage_1 = { id: null };
        } else this.activeTab = 1;
        if (this.isEmpty(selectedItem.stage_2)) {
          selectedItem.stage_2 = { id: null };
        } else this.activeTab = 2;
        if (this.isEmpty(selectedItem.stage_3)) {
          selectedItem.stage_3 = { id: null };
        } else this.activeTab = 3;
        this.selectedItem = cloneDeep(selectedItem);
        this.cloneItem = cloneDeep(selectedItem);
        this.setItems(selectedItem);
        this.flagEditForm = false;
        this.disabledEdit = false;
        this.loadDetail = false;

        this.$nextTick(() => {
          if (this.$refs.stepOne) {
            this.$refs.stepOne.setForm();
          }
          if (this.$refs.stepTwo) {
            this.$refs.stepTwo.setForm();
          }
          if (this.$refs.stepThree) {
            this.$refs.stepThree.setForm();
          }
        });

        let tipoProceso = {};
        (tipoProceso["procesos-judiciales-administrativos"] =
          "ju_pro_adm_conts"),
          (tipoProceso["procesos-judiciales-civiles"] = "ju_pro_civ_pros"),
          (tipoProceso["procesos-judiciales-constitucionales"] =
            "ju_pro_consts"),
          (tipoProceso["procesos-judiciales-penales"] = "ju_pro_cri_pros");


        const act = await this.axios
          .get(
            `https://api-jaru-cej.estudiorodrigo.com/expedientesCEJ/contar_nuevas_actuaciones/?tipoProceso=${tipoProceso[this.typeSubmodule]}&idProceso=${item.id}`,
            {
              headers: {
                "x-api-key":
                  "FXD12Wh2tZtW0K6GaBHhcu5bVRYZxI23jthKtloruV1S3lHcjT9dgjl7Gfnh9zkt",
              },
            },
          )
          .then(({ data }) => {
            this.actuaciones = data.data;
            console.log(this.actuaciones);
          });
      }
    },
    setItems(selectedItem) {
      this.inputFileCode = selectedItem.file_code;
      this.inputAmount = selectedItem.amount;
      this.inputControversialMatter = selectedItem.controversial_matter;
      this.inputComplainingParty = selectedItem.complaining_party;
      this.inputRespondentParty = selectedItem.respondent_party;
      this.selectedCargoStudy = selectedItem.cargo_study;
      this.selectedResponsible = selectedItem.responsible;
      this.selectedSecondaryResponsible = selectedItem.secondary_responsible;
      this.selectedProject = selectedItem.project;
    },
    clearErrors() {
      // clear roles
      this.rulesControversialMatter = [];
      this.rulesAmount = [];
      this.rulesRespondentParty = [];
      this.rulesComplainingParty = [];
      this.rulesCargoStudy = [];
      this.rulesFileCode = [];
      this.rulesProject = [];
      // clear listError
      this.listError = {
        respondent_party: "",
        complaining_party: "",
        controversial_matter: "",
        amount: "",
        file_code: "",
        project_id: "",
        cargo_study_id: "",
        responsible_id: "",
      };
      // clear Inputs
      this.inputFileCode = null;
      this.inputRespondentParty = null;
      this.inputComplainingParty = null;
      this.inputControversialMatter = null;
      this.inputAmount = null;
      this.selectedCargoStudy = null;
      this.selectedProject = null;
      this.selectedResponsible = null;
    },

    onChangeFileCode(fileCode) {
      if (fileCode === "") {
        this.preventResponsibleEmptyInput();
      }
      this.listError.file_code = null;
      this.inputFileCode = fileCode;
    },

    preventResponsibleEmptyInput() {
      this.flagEditForm = true;
      this.selectedResponsible = this.selectedItem.responsible;
      this.selectedSecondaryResponsible = this.selectedItem.secondary_responsible;
    },

    onChangeRespondentParty(respondentParty) {
      if (respondentParty === "") {
        this.preventResponsibleEmptyInput();
      }
      this.listError.respondent_party = null;
      this.inputRespondentParty = respondentParty;
    },

    onChangeComplainingParty(complainingParty) {
      if (complainingParty === "") {
        this.preventResponsibleEmptyInput();
      }
      this.listError.complaining_party = null;
      this.inputComplainingParty = complainingParty;
    },

    onChangeCargoStudy(cargoStudy) {
      if (cargoStudy === null) {
        this.preventResponsibleEmptyInput();
      }
      this.listError.cargo_study_id = null;
      this.selectedCargoStudy = cargoStudy;
    },

    onChangeResponsible(responsible) {
      if (responsible === null) {
        this.preventResponsibleEmptyInput();
      }
      this.listError.responsible_id = null;
      this.selectedResponsible = responsible;
    },

    onChangeSecondaryResponsible(responsible) {
      if (responsible === null) {
        this.preventResponsibleEmptyInput();
      }
      this.flagEditForm = true;
      this.listError.responsible_secondary_id = null;
      this.selectedSecondaryResponsible = responsible;
    },

    onChangeProject(project) {
      if (project === null) {
        this.preventResponsibleEmptyInput();
      }
      this.listError.project_id = null;
      this.selectedProject = project;
    },

    onChangeControversialMatter(controversialMatter) {
      this.flagEditForm = true;
      this.listError.controversial_matter = null;
      this.inputControversialMatter = controversialMatter;
    },

    onChangeAmount(amount) {
      if (amount === "") {
        this.preventResponsibleEmptyInput();
      }
      this.listError.amount = null;
      this.inputAmount = amount;
    },

    changeLoadingSubmit(flag) {
      if (this.isContinue) {
        this.loadContinue = flag;
      } else {
        this.loadCreate = flag;
      }
    },
    /* Escucha  para activar un tab, se activa cuando hacemos un cambio en algun input de los componentes hijos | bjar*/
    changeFlagQuestion(flag, step) {
      ////Activa las instancias 1/2/3
      //console.log("flag: " + flag, "step: " + step);
      //console.log(flag);
      if (step === "one") {
        this.flagStepOne = flag;
      } else if (step === "two") {
        this.flagStepTwo = flag;
      } else if (step === "three") {
        this.flagStepThree = flag;
      }
    },

    styleCursorStep(step = 1) {
      let flag = !this.isEmpty(this.selectedItem[`stage_${step}`].id);
      return `cursor: ${flag ? "pointer" : "initial"}`;
    },
  },
};
</script>
