<template>
  <v-card-text class="pb-0">
    <v-form
      ref="formUpdate"
      v-model="validUpdate"
      :lazy-validation="lazyUpdate"
    >
      <v-row>
        <v-col
          v-if="typeDialog !== 'new' && !isEmpty(selectedItem)"
          cols="6"
          class="py-0"
        >
          <v-text-field
            v-model="selectedItem.id"
            disabled
            outlined
            label="Código"
            height="56"
            hide-details
            class="pt-0 input-custom-56"
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="py-0">
          <v-text-field
            v-model="inputType"
            label="Obligación*"
            height="56"
            class="pt-0 input-custom-56"
            outlined
            :disabled="disabledOptions"
            :hide-details="isEmpty(listError.type)"
            :rules="rulesType"
            @input="onChangeType"
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="py-0">
          <v-autocomplete
            v-model="periodicity"
            class="pt-0 input-custom-56"
            label="Periodicidad*"
            item-text="label"
            item-value="id"
            outlined
            height="56"
            return-object
            hide-details
            :items="listPeriodicity.options"
            :rules="rulesPeriodicity"
            :hide-details="isEmpty(listError.periodicity)"
            :disabled="disabledOptions"
            @input="onChangePeriodicity"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" class="py-0">
          <v-text-field
            v-model="entityTransmitter"
            label="Entidad Emisora*"
            height="56"
            class="pt-0 input-custom-56"
            outlined
            :disabled="disabledOptions"
            :hide-details="isEmpty(listError.entity_emisor)"
            :rules="rulesEntityTransmitter"
            @input="onChangeEntityTransmitter"
          ></v-text-field>
        </v-col>
        <v-col :cols="typeDialog !== 'new' && !isEmpty(selectedItem) ? 12 : 6" class="py-0">
          <v-text-field
            v-model="internArea"
            label="Área Interna*"
            height="56"
            class="pt-0 input-custom-56"
            outlined
            :disabled="disabledOptions"
            :hide-details="isEmpty(listError.intern_area)"
            :rules="rulesInternArea"
            @input="onChangeInternArea"
          ></v-text-field>
        </v-col>
        <responsable-section
          ref="responsibleSection"
          :selected-item="selectedItem"
          :type-dialog="typeDialog"
          :filter-ejecutor="true"
          :type-submodule="typeSubmodule"
          :list-error="listError"
          :disabled="disabledOptions"
          @clear-error="onClearErrorResponsible"
        ></responsable-section>
        <v-col cols="12" class="py-0">
          <v-autocomplete
            v-model="selectedApplicability"
            class="pt-0 input-custom-56"
            label="Aplicabilidad*"
            item-text="label"
            item-value="id"
            outlined
            height="56"
            return-object
            hide-details
            :list-error="listError"
            :items="listApplicability.options"
            :disabled="disabledOptions"
            @input="onChangeApplicability"
            :hide-details="isEmpty(listError.applicability)"
            :rules="rulesApplicability"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-text-field
            v-model="inputNumberRule"
            label="N° de Norma*"
            height="56"
            class="pt-0 input-custom-56"
            outlined
            :disabled="disabledOptions"
            :hide-details="isEmpty(listError.number_rule)"
            :rules="rulesNumberRule"
            @input="onChangeNumberRule"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-autocomplete
            v-model="selectedProject"
            class="pt-0 input-custom-56"
            label="Proyecto*"
            item-text="label"
            item-value="id"
            outlined
            height="56"
            return-object
            hide-details
            :list-error="listError"
            :items="listProjects.options"
            :disabled="disabledOptions"
            @input="onChangeProject"
            :hide-details="isEmpty(listError.project_id)"
            :rules="rulesProject"
          ></v-autocomplete>
        </v-col>
        <!--cambio de etiqueta partes-->
        <v-col cols="12" class="py-0 mb-5">
          <div class="flex flex-column gap-5">
            <label for="" class="text-xl vue-editor-label" :aria-disabled="disabledOptions">{{typeSubmodule == 'obligaciones-tecnicas'
              ? 'Permiso autorización o licencia'
              : 'Dispositivo Legal y Modificaciones'}}</label>
            <vue-editor
              id="editor"
              v-model="inputParts"
              :disabled="disabledOptions"
              :hide-details="isEmpty(listError.project_id)"
              :rules="rulesProject"
              @input="debounceParts"
            >
            </vue-editor>
          </div>
        </v-col>
        <v-col cols="12" class="py-0 mb-5">
          <div class="flex flex-column gap-5">
            <label for="" class="text-xl vue-editor-label" :aria-disabled="disabledOptions">Nombre Norma Legal</label>
            <vue-editor
              id="editor"
              v-model="inputDetail"
              :disabled="disabledOptions"
              @input="onChangeDetail"
            >
            </vue-editor>
          </div>
        </v-col>
        <v-col cols="12" class="py-0 mb-5">
          <div class="flex flex-column gap-5">
            <label for="" class="text-xl vue-editor-label" :aria-disabled="disabledOptions">Texto de la Norma</label>
            <vue-editor
              id="editor"
              v-model="inputStandardText"
              :disabled="disabledOptions"
              @input="onChangeStandardText"
            >
            </vue-editor>
            <div class="v-text-field__details" v-if="listError.standard_text !== null">
              <p class="red--text">{{listError.standard_text}}</p>
            </div>
          </div>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-text-field
            v-model="inputArticleNumber"
            label="N° de Artículo*"
            height="56"
            class="pt-0 input-custom-56"
            outlined
            :disabled="disabledOptions"
            :hide-details="isEmpty(listError.article_number)"
            :rules="rulesArticleNumber"
            @input="onChangeArticleNumber"
          ></v-text-field>
        </v-col>
        <v-col
          v-if="
            typeSubmodule == 'obligaciones-internas' ||
            typeSubmodule == 'laboral'
          "
          cols="12"
          class="py-0 mb-5"
        >
          <v-textarea
            v-model="inputOportunity"
            label="Oportunidad y/o plazo de cumplimiento"
            class="pt-0 mb-0"
            outlined
            :disabled="disabledOptions"
            :hide-details="isEmpty(listError.oportunity)"
            :rules="rulesOportunity"
            @input="onChangeOportunity"
          ></v-textarea>
        </v-col>
        <v-col cols="12" class="py-0 mb-0">
          <label for="" class="text-xl vue-editor-label" :aria-disabled="disabledOptions">{{typeSubmodule == 'obligaciones-formales'
            ? 'Sanciones OEFA'
            : typeSubmodule == 'obligaciones-internas'
              ? 'Sanciones SUNAFIL'
              : typeSubmodule == 'laboral'
                ? 'Sanciones SUNAFIL'
                : 'Observaciones'}}</label>
          <vue-editor
            id="editor"
            v-model="inputMainObligations"
            :hide-details="isEmpty(listError.main_obligations)"
            :rules="rulesMainObligations"
            :disabled="disabledOptions"
            @input="onChangeMainObligations"
          >
          </vue-editor>
        </v-col>
        <v-col
          v-if="typeSubmodule == 'obligaciones-tecnicas'"
          cols="12"
          class="py-0 mb-5"
          style="margin-top: 20px"
        >
          <v-textarea
            v-model="inputMainAutoridad"
            class="pt-0 mb-0"
            :rows="3"
            label="Autoridad Competente"
            outlined
            :hide-details="isEmpty(listError.autoridad_competente)"
            :rules="rulesMainAutoridad"
            :disabled="disabledOptions"
            @input="onChangeMainAutoridad"
          ></v-textarea>
        </v-col>
        <v-col
          v-if="typeSubmodule == 'obligaciones-tecnicas'"
          cols="6"
          class="py-0"
        >
          <v-text-field
            v-model="inputPlazoLegal"
            label="Plazo Legal"
            height="56"
            class="pt-0 input-custom-56"
            outlined
            :disabled="disabledOptions"
            :hide-details="isEmpty(listError.plazoLegal)"
            :rules="rulesPlazoLegal"
            @input="onChangePlazoLegal"
          ></v-text-field>
        </v-col>
        <v-col
          v-if="typeSubmodule == 'obligaciones-tecnicas'"
          cols="6"
          class="py-0"
        >
          <v-text-field
            v-model="inputPlazoEstimado"
            label="Plazo Estimado"
            height="56"
            class="pt-0 input-custom-56"
            outlined
            :disabled="disabledOptions"
            :hide-details="isEmpty(listError.plazoEstimado)"
            :rules="rulesPlazoEstimado"
            @input="onChangePlazoEstimado"
          ></v-text-field>
        </v-col>
        <v-col
          v-if="typeSubmodule == 'obligaciones-tecnicas'"
          cols="12"
          class="py-0 mb-5"
        >
          <v-textarea
            v-model="inputConsecuencias"
            class="pt-0 mb-0"
            :rows="3"
            label="Consecuencias de Incumplimiento"
            outlined
            :hide-details="isEmpty(listError.consecuencias)"
            :rules="rulesConsecuencias"
            :disabled="disabledOptions"
            @input="onChangeConscuencias"
          ></v-textarea>
        </v-col>
        <v-col
          v-if="typeSubmodule == 'obligaciones-internas'"
          cols="12"
          class="py-0 mb-5"
          style="margin-top: 20px"
        >
          <v-textarea
            v-model="inputoSancionesOsinergmin"
            class="pt-0 mb-0"
            :rows="3"
            label="Sanciones OSINERGMIN"
            outlined
            :hide-details="isEmpty(listError.sanciones_osinergmin)"
            :rules="rulesSanciones"
            :disabled="disabledOptions"
            @input="onChangeSanciones"
          ></v-textarea>
        </v-col>
        <v-col
          v-if="typeSubmodule == 'obligaciones-internas'"
          cols="12"
          class="py-0 mb-5"
        >
          <v-textarea
            v-model="inputObservaciones"
            class="pt-0 mb-0"
            :rows="3"
            label="Observaciones"
            outlined
            :hide-details="isEmpty(listError.input_observaciones)"
            :rules="rulesObservaciones"
            :disabled="disabledOptions"
            @input="onChangeObservaciones"
          ></v-textarea>
        </v-col>
        <v-col
          v-if="typeSubmodule == 'obligaciones-formales'"
          cols="12"
          class="py-0 mb-5"
        >
          <v-textarea
            v-model="inputObservacionesAmbiental"
            class="pt-0 mb-0"
            :rows="3"
            label="Observaciones"
            outlined
            :hide-details="isEmpty(listError.input_observaciones)"
            :rules="rulesObservaciones"
            :disabled="disabledOptions"
            @input="onChangeObservacionesAmbiental"
          ></v-textarea>
        </v-col>
        <v-col cols="12" class="py-0 mt-5 mb-4">
          <v-divider></v-divider>
        </v-col>
        <check-list-task
          :id="selectedItem.id"
          class="mb-0"
          :tasks="selectedItem.pending_tasks"
          :type="typeDialog"
          :type-module="typeModule"
          :type-submodule="typeSubmodule"
          :is-drafts="isDrafts"
          :filter-ejecutor="true"
          :disabled="disabledOptions"
          @update="onChangePendingTask"
        ></check-list-task>
        <v-col cols="12" class="py-0 mt-2 mb-3">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" v-for="(extended) in extendedAttributes" :key="extended.group">
          <div class="flex flex-column">
            <h1 class="text-left mb-5">{{extended.group}}</h1>
            <v-row>
              <v-col cols="12" v-for="(attrib, index) in extended.attributes" :key="attrib.id">
                <div v-if="attrib.data_type === 'string'">
                  <v-textarea
                    :label="attrib.label"
                    class="pt-0 mb-0"
                    outlined
                    :disabled="disabledOptions"
                    v-if="dynamicAttributes.find((dynamic) => dynamic.entity_attribute_id === attrib.id) !== undefined"
                    :value="getValueField(attrib.id)"
                    @input="onDynamicField(index, attrib.id, $event)"
                  ></v-textarea>

                  <v-textarea
                    :label="attrib.label"
                    class="pt-0 mb-0"
                    outlined
                    :disabled="disabledOptions"
                    v-else
                    @input="onDynamicField(index, attrib.id, $event)"
                  ></v-textarea>
                </div>
                <div v-if="attrib.data_type === 'boolean'">
                  <v-autocomplete
                    v-if="dynamicAttributes.find((dynamic) => dynamic.entity_attribute_id === attrib.id) !== undefined"
                    :value="getValueField(attrib.id)"
                    class="pt-0 input-custom-56"
                    :label="attrib.label"
                    item-text="label"
                    item-value="id"
                    outlined
                    height="56"
                    return-object
                    hide-details
                    :items="listFulfilled.options"
                    :disabled="disabledOptions"
                    @input="onDynamicField(index, attrib.id, $event)"
                  ></v-autocomplete>
                  <v-autocomplete
                    v-else
                    class="pt-0 input-custom-56"
                    :label="attrib.label"
                    item-text="label"
                    item-value="id"
                    outlined
                    height="56"
                    return-object
                    hide-details
                    :items="listFulfilled.options"
                    :disabled="disabledOptions"
                    @input="onDynamicField(index, attrib.id, $event)"
                  ></v-autocomplete>
                </div>
                <div v-if="attrib.data_type === 'date'">
                  <v-menu
                    ref="pickerEnd"
                    v-model="showDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        slot="activator"
                        class="pt-0 input-custom-56"
                        :label="attrib.label"
                        :value="getValueField(attrib.id)"
                        outlined
                        height="56"
                        :disabled="disabledOptions"
                        append-icon="event"
                        readonly
                        @click:append="showDate = true"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :value="getValueField(attrib.id)"
                      no-title
                      scrollable
                      reactive
                      :show-current="true"
                      locale="es"
                      :allowed-dates="allowedDates"
                      @input="onDynamicField(index, attrib.id, $event, attrib.data_type)"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col v-if="!isEmpty(spij)" cols="12" class="py-0 mb-0">
          <p class="mb-1 card-input-title">SPIJ</p>
          <v-row>
            <v-col cols="12" md="4" class="py-0">
              <a :href="spij.spij_url" target="_blank">{{ spij.spij_url }}</a>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                v-model="spij.spij_user"
                outlined
                readonly
                label="Usuario"
                height="56"
                hide-details
                append-icon="link"
                class="pt-0 my-0"
                @click:append="onCopyText('spij_user', spij.spij_user)"
              ></v-text-field>
              <input id="spij_user" type="hidden" :value="spij.spij_user" />
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                v-model="spij.spij_password"
                outlined
                readonly
                label="Contraseña"
                height="56"
                hide-details
                append-icon="link"
                class="pt-0 my-0"
                @click:append="onCopyText('spij_password', spij.spij_password)"
              ></v-text-field>
              <input
                id="spij_password"
                type="hidden"
                :value="spij.spij_password"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-card-text>
</template>
<script>
import {mapState} from "vuex";
import {debounce} from "lodash";
import ResponsableSection from "../commons/ResponsableSection";
import CheckListTask from "../commons/CheckListTask";
import BlockCounter from "../commons/BlockCounter";
import MapSection from "../commons/MapSection";

export default {
  name: "CommitmentsSection",
  components: {
    ResponsableSection,
    // eslint-disable-next-line vue/no-unused-components
    BlockCounter,
    CheckListTask,
    // eslint-disable-next-line vue/no-unused-components
    MapSection,
  },
  model: { prop: "selectedItem", event: "change" },
  props: {
    selectedItem: { type: Object, default: () => {} },
    spij: { type: Object, default: () => {} },
    typeModule: { type: String, default: "" },
    typeSubmodule: { type: String, default: "" },
    typeDialog: { type: String, default: "new" },
    disabledEdit: { type: Boolean, default: false },
    isDrafts: { type: Boolean, default: false },
    extendedAttributes: { type: Array, default: false },
  },
  data: () => ({
    validUpdate: false,
    lazyUpdate: false,
    form: {},
    copyText: "",
    inputDetail: null,
    // inputTerm: 0,
    inputType: null,
    inputArticleNumber: null,
    inputNumberRule: null,
    inputOportunity: null,
    inputParts: null,
    inputMainObligations: null,
    inputStandardText: null,
    inputMainAutoridad: null,
    inputMedidasCorrectivas: null,
    inputObservaciones: null,
    inputObservacionesAmbiental: null,
    inputoSancionesOsinergmin: null,
    inputConsecuencias: null,
    inputPlazoEstimado: null,
    inputPlazoLegal: null,
    selectedSituation: null,
    selectedProject: null,
    selectedApplicability: null,
    periodicity: null,
    entityTransmitter: null,
    internArea: null,
    dynamicAttributes: [],
    nodata: [],
    listPeriodicity: {
      options: [
        "Permanente",
        "No aplica",
        "Cuando corresponda (ver observaciones)",
        "Semanal",
        "Semestral",
        "Trimestral",
      ]
    },
    listApplicability: {
      options: [
        "Permanente",
        "No aplica",
        "Cuando corresponde",
        "De vez en cuando",
      ]
    },
    listFulfilled: {
      options: [
        "Sí",
        "No",
      ]
    },
    // debounce
    debounceDetail: null,
    debounceParts: null,
    debounceMainObligations: null,
    showDate: false,
    // date picker
    dateExpiration: null,
    showDateExpiration: false,
    expirationDateDisabled: true,
    // rules
    rulesType: [],
    rulesEntityTransmitter: [],
    rulesInternArea: [],
    rulesPeriodicity: [],
    rulesArticleNumber: [],
    rulesNumberRule: [],
    rulesOportunity: [],
    rulesProject: [],
    rulesApplicability: [],
    rulesParts: [],
    rulesSituation: [],
    rulesExpiration: [],
    rulesMainObligations: [],
    rulesStandardText: [],
    rulesMainAutoridad: [],
    rulesCorrectivas: [],
    rulesObservaciones: [],
    rulesSanciones: [],
    rulesPlazoLegal: [],
    rulesPlazoEstimado: [],
    rulesConsecuencias: [],
    // error
    listError: {
      type: "",
      oportunity: "",
      applicability: "",
      situation_id: "",
      project_id: "",
      periodicity: "",
      article_number: "",
      number_rule: "",
      entity_emisor: "",
      intern_area: "",
      authority_id: "",
      parts: "",
      term: "",
      main_obligations: "",
      // user
      primary_responsible_id: "",
      secondary_responsible_id: "",
      // date
      expiration_date: "",
      autoridad_competente: "",
      medidas_correctivas: "",
      input_observaciones: "",
      sanciones_osinergmin: "",
      plazoLegal: "",
      plazoEstimado: "",
      consecuencias: "",
    },
  }),
  computed: {
    ...mapState({
      listMasters: (state) => state.masters.listMasters,
      listSituations: (state) => state.masters.listSituations,
      listProjects: (state) => state.masters.listProjects,
    }),
    dateFormattedExpiration() {
      return this.formatDate(this.dateExpiration);
    },
    disabledOptions() {
      if (this.typeDialog === "edit") {
        return !this.disabledEdit;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.debounceDetail = debounce(this.onChangeDetail, 300);
    this.debounceParts = debounce(this.onChangeParts, 300);
    this.debounceMainObligations = debounce(this.onChangeMainObligations, 300);

    if (!this.isDrafts) {
      setTimeout(() => {
        this.dynamicAttributes = this.extendedAttributes.reduce((accumulator, group) => {
          return accumulator.concat(group.attributes);
        }, []).map((attrib) => ({  value: '', entity_attribute_id: attrib.id }));
      }, 300);
    }
  },
  methods: {
    clearErrorSection() {
      if (this.$refs.responsibleSection)
        this.$refs.responsibleSection.resetValidation();

      if (this.$refs.formUpdate) this.$refs.formUpdate.resetValidation();
      // set errror
      this.$nextTick(() => {
        this.listError = {
          type: "",
          oportunity: "",
          situation_id: "MTOP000004",
          project_id: "",
          authority_id: "",
          term: "",
          parts: "",
          main_obligations: "",
          // user
          primary_responsible_id: "",
          secondary_responsible_id: "",
          // date
          expiration_date: "",
        };
      });
    },
    listErrorSection(err) {
      const {
        type = null,
        oportunity = null,
        situation_id = null,
        term = null,
        parts = null,
        project_id = null,
        expiration_date = null,
        main_obligations = null,
        intern_area = null,
        entity_emisor = null,
        periodicity = null,
        article_number = null,
        standard_text = null,
        applicability = null,
        number_rule = null,
      } = err;
      this.showError = true;
      this.listError.main_obligations = main_obligations
        ? main_obligations[0]
        : null;
      this.listError.situation_id = situation_id ? situation_id[0] : null;
      this.listError.intern_area = intern_area ? intern_area[0] : null;
      this.listError.entity_emisor = entity_emisor ? entity_emisor[0] : null;
      this.listError.periodicity = periodicity ? periodicity[0] : null;
      this.listError.article_number = article_number ? article_number[0] : null;
      this.listError.standard_text = standard_text ? standard_text[0] : null;
      this.listError.applicability = applicability ? applicability[0] : null;
      this.listError.number_rule = number_rule ? number_rule[0] : null;
      this.listError.type = type ? type[0] : null;
      this.listError.oportunity = oportunity ? oportunity[0] : null;
      this.listError.term = term ? term[0] : null;
      this.listError.parts = parts ? parts[0] : null;
      this.listError.project_id = project_id ? project_id[0] : null;
      this.listError.expiration_date = expiration_date
        ? expiration_date[0]
        : null;
      // Rules
      this.rulesMainObligations = this.listError.main_obligations
        ? [() => this.listError.main_obligations]
        : [];
      this.rulesParts = this.listError.parts
        ? [() => this.listError.parts]
        : [];
      this.rulesType = this.listError.type ? [() => this.listError.type] : [];
      this.rulesInternArea = this.listError.intern_area ? [() => this.listError.intern_area] : [];
      this.rulesPeriodicity = this.listError.periodicity ? [() => this.listError.periodicity] : [];
      this.rulesEntityTransmitter = this.listError.entity_emisor ? [() => this.listError.entity_emisor] : [];
      this.rulesArticleNumber = this.listError.article_number ? [() => this.listError.article_number] : [];
      this.rulesNumberRule = this.listError.number_rule ? [() => this.listError.number_rule] : [];
      this.rulesProject = this.listError.project_id
        ? [() => this.listError.project_id]
        : [];
      this.rulesApplicability = this.listError.applicability
        ? [() => this.listError.applicability]
        : [];
      this.rulesSituation = this.listError.situation_id
        ? [() => this.listError.situation_id]
        : [];
      this.rulesExpiration = this.listError.expiration_date
        ? [() => this.listError.expiration_date]
        : [];
      this.$refs.formUpdate.validate();
      this.$nextTick(() => {
        this.$refs.responsibleSection.validateError(err);
        this.$refs.mapSection.validateError(err);
        this.$refs.term.validateError(term);
      });
    },
    setForm() {
      this.rulesType = [];
      this.rulesProject = [];
      this.rulesSituation = [];
      this.rulesExpiration = [];
      this.rulesMainObligations = [];
      this.rulesParts = [];
      this.$nextTick(() => {
        const {
          detail = null,
          project,
          situation = this.listSituations.options[0],
          type = null,
          oportunity = null,
          periodicity,
          intern_area,
          entity_emisor,
          article_number,
          // term,
          parts,
          main_obligations,
          expiration_date,
          expiration_date_disabled = true,
          autoridad_competente,
          plazo_estimado,
          plazo_legal,
          consecuencia_incumplimiento,
          medidad_correctivas,
          observaciones,
          observaciones_ambiental,
          sansiones_osinergmin,
          extended_attributes_values,
          standard_text,
          applicability,
          number_rule,
        } = this.selectedItem
        this.selectedProject = project;
        this.selectedSituation = situation;
        this.selectedApplicability = applicability;
        this.inputType = type;
        this.inputOportunity = oportunity;
        this.inputDetail = detail;
        this.inputMainAutoridad = autoridad_competente;
        this.inputPlazoLegal = plazo_legal;
        this.inputPlazoEstimado = plazo_estimado;
        this.inputConsecuencias = consecuencia_incumplimiento;
        this.inputMedidasCorrectivas = medidad_correctivas;
        this.inputObservaciones = observaciones;
        this.inputObservacionesAmbiental = observaciones_ambiental;
        this.inputoSancionesOsinergmin = sansiones_osinergmin;
        this.internArea = intern_area;
        this.periodicity = periodicity;
        this.entityTransmitter = entity_emisor;
        this.inputArticleNumber = article_number;
        this.inputStandardText = standard_text;
        this.inputNumberRule = number_rule;
        // this.inputTerm = term;
        this.inputParts = parts;
        const concatExtended = this.extendedAttributes.reduce((accumulator, group) => {
          return accumulator.concat(group.attributes);
        }, []);

        this.dynamicAttributes = concatExtended.map((extended) => {
          const match = extended_attributes_values.find((attrib) => attrib.entity_attribute_id === extended.id);

          if (match) {
            return {
              ...match,
            }
          }

          return {
            value: "",
            entity_attribute_id: extended.id,
          }
        });

        if (this.isDrafts) {
          this.$emit("draft-extended", this.dynamicAttributes);
        }


        this.inputMainObligations = main_obligations;
        this.dateExpiration = expiration_date;
        this.expirationDateDisabled = expiration_date_disabled;
        this.$refs.mapSection.setForm();
        this.$refs.responsibleSection.setForm();
      });
    },
    clearFormSection() {
      this.selectedProject = null;
      this.selectedSituation =
        this.listSituations != null ? this.listSituations.options[0].id : null;
      this.inputType = null;
      this.inputOportunity = null;
      this.periodicity = null;
      this.inputArticleNumber = null;
      this.dynamicAttributes = this.extendedAttributes.reduce((accumulator, group) => {
        return accumulator.concat(group.attributes);
      }, []).map((attrib) => ({  value: '', entity_attribute_id: attrib.id }));
      this.entityTransmitter = null;
      this.internArea = null;
      this.inputParts = null;
      this.inputMainObligations = null;
      this.dateExpiration = null;
      this.inputDetail = null;
      this.expirationDateDisabled = true;
      this.inputMainAutoridad = null;
      this.inputPlazoLegal = null;
      this.inputPlazoEstimado = null;
      this.inputConsecuencias = null;
      this.inputObservaciones = null;
      this.inputObservacionesAmbiental = null;
      this.inputObservaciones = null;
      this.inputoSancionesOsinergmin = null;
      this.inputStandardText = null;
      this.inputNumberRule = null;
      this.selectedApplicability = null;
      // this.inputTerm = 0;
      if (this.$refs.term) {
        this.$refs.term.clearError();
      }
    },
    onChangeOportunity(oportunity) {
      this.listError.oportunity = null;
      this.selectedItem.oportunity = oportunity ? oportunity : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeType(type) {
      this.listError.type = null;
      this.selectedItem.type = type ? type : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeArticleNumber(article_number) {
      this.listError.article_number = null;
      this.selectedItem.article_number = article_number ? article_number : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeNumberRule(number_rule) {
      this.listError.number_rule = null;
      this.selectedItem.number_rule = number_rule ? number_rule : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeEntityTransmitter(entity_transmitter) {
      this.listError.entity_emisor = null;
      this.selectedItem.entity_emisor = entity_transmitter ? entity_transmitter : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeInternArea(intern_area) {
      this.listError.intern_area = null;
      this.selectedItem.intern_area = intern_area ? intern_area : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeSituation(situation) {
      this.selectedItem.situation = situation ? situation : null;
      this.listError.situation_id = null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeResponsible(primary, secondary) {
      this.selectedItem.primary_responsible = primary ? primary : null;
      this.selectedItem.secondary_responsible = secondary ? secondary : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeProject(project) {
      this.selectedItem.project = project ? project : null;
      this.listError.project_id = null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeApplicability(applicability) {
      this.selectedItem.applicability = applicability ? applicability : null;
      this.listError.applicability = null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangePeriodicity(periodicity) {
      this.selectedItem.periodicity = periodicity ? periodicity : null;
      this.listError.periodicity = null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeDetail(detail) {
      this.selectedItem.detail = detail ? detail : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeDateExpiration(expiration) {
      this.selectedItem.expiration_date = expiration ? expiration : null;
      this.selectedItem.expiration_date_disabled = this.expirationDateDisabled;
      this.showDateExpiration = false;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeDateDisabled(flag) {
      this.selectedItem.expiration_date_disabled = flag;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeTerm(term) {
      this.listError.term = null;
      this.selectedItem.term = term ? term : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeLocationDisabled() {
      this.$emit("change-form");
    },
    onChangeLocations(locations) {
      this.selectedItem.locations = locations ? locations : [];
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeDatumZone(datum, zone) {
      this.selectedItem.datum = datum ? datum : [];
      this.selectedItem.zone = zone ? zone : [];
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangePendingTask(task) {
      this.selectedItem.pending_tasks = task ? task : [];
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeParts(parts) {
      this.listError.parts = null;
      this.selectedItem.parts = parts ? parts : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    getValueField(entity_attribute_id) {
      const attribute = this.dynamicAttributes.find((dynamic) => dynamic.entity_attribute_id === entity_attribute_id)

      return attribute === undefined ? "" : attribute.value;
    },
    onDynamicField(index, entity_attribute_id, value, data_type = null) {
      if (data_type !== null) {
        this.showDate = false;
      }

      const dynamic = this.dynamicAttributes.find((dynamic) => dynamic.entity_attribute_id === entity_attribute_id);
      const dynamicIndex = this.dynamicAttributes.indexOf(dynamic);

      this.dynamicAttributes[dynamicIndex].value = value;
      this.dynamicAttributes[dynamicIndex].entity_attribute_id = entity_attribute_id;

      this.$emit("change-dynamic-attributes", this.dynamicAttributes);
    },
    onChangeMainObligations(main_obligations) {
      this.listError.main_obligations = null;
      this.selectedItem.main_obligations = main_obligations
        ? main_obligations
        : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeStandardText(standard_text) {
      this.listError.standard_text = null;
      this.selectedItem.standard_text = standard_text
        ? standard_text
        : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeMainAutoridad(autoridad_competente) {
      this.selectedItem.autoridad_competente = autoridad_competente
        ? autoridad_competente
        : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeObservaciones(observaciones) {
      this.selectedItem.observaciones = observaciones ? observaciones : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeObservacionesAmbiental(observaciones_ambiental) {
      this.selectedItem.observaciones_ambiental = observaciones_ambiental
        ? observaciones_ambiental
        : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    /*onChangeCorrectivas(medidad_correctivas) {
      this.selectedItem.medidad_correctivas = medidad_correctivas
        ? medidad_correctivas
        : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },*/
    onChangeSanciones(sansiones_osinergmin) {
      this.selectedItem.sansiones_osinergmin = sansiones_osinergmin
        ? sansiones_osinergmin
        : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangePlazoLegal(plazo_legal) {
      this.selectedItem.plazo_legal = plazo_legal ? plazo_legal : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangePlazoEstimado(plazo_estimado) {
      this.selectedItem.plazo_estimado = plazo_estimado ? plazo_estimado : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeConscuencias(consecuencia_incumplimiento) {
      this.selectedItem.consecuencia_incumplimiento =
        consecuencia_incumplimiento ? consecuencia_incumplimiento : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    formatDate(date) {
      if (!date) return null;
      const parseDate = date.split(" ")[0];
      const [year, month, day] = parseDate.split("-");
      return `${day}/${month}/${year}`;
    },
    onClearErrorResponsible(type) {
      if (type === "primary") {
        this.listError.primary_responsible_id = null;
      } else if (type === "secondary") {
        this.listError.secondary_responsible_id = null;
      }
    },
    onCopyText(id) {
      let inputPassword = document.querySelector(`#${id}`);
      inputPassword.setAttribute("type", "text");
      inputPassword.select();
      try {
        const successful = document.execCommand("copy");
        const msg = successful ? "successful" : "unsuccessful";
        if (msg === "successful") this.copyText = "Copiado";
      } catch (err) {
        console.error(err);
      }
      inputPassword.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
      setTimeout(() => {
        this.copyText = "";
      }, 500);
    },
    allowedDates() {
      // const actDate = new Date();
      // return new Date(val) > actDate.setDate(actDate.getDate() - 1);
      return true;
    },
  },
};
</script>

