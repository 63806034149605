<template>
  <v-card class="block-report" flat tile>
    <v-card-text>
      <v-row>
        <template v-if="loading">
          <v-col cols="12">
            <h4 class="subtitle-2 primary--text font-weight-black">
              Gráfico 1 de 1
            </h4>
            <horizontal-bar-chart
              v-if="loading"
              ref="reportTwo1"
              chart-id="reportTwo1"
              :chart-data="optionChartColumn"
              :show-legend="false"
              title="Fichas por vencer al día de hoy"
            ></horizontal-bar-chart>
            <v-row v-if="loading">
              <v-col cols="12" class="text-right">
                <v-btn
                  class="ma-0"
                  color="action"
                  dark
                  depressed
                  small
                  :loading="loadTwo1"
                  @click="onDownloadChart('reportTwo1')"
                  >Descargar Reporte
                </v-btn>
              </v-col>
              <v-col cols="12" class="text-left">
                <v-simple-table class="table-report table-small" :dense="true">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Situación</th>
                        <th class="text-left">Cantidad</th>
                        <th class="text-left">%</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in dataTable" :key="index">
                        <td>{{ item.label }}</td>
                        <td>{{ item.value }}</td>
                        <td>{{ formatPercentage(item.value, dataTotal) }}</td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td>{{ dataTotal }}</td>
                        <td>100%</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-col>
        </template>
        <template v-else>
          <v-col cols="12" class="text-center mt-5">
            <v-progress-circular
              :size="22"
              class="d-inline-block mr-3"
              :width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <span class="primary--text">Cargando reporte</span>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import HorizontalBarChart from "./charts/HorizontalBarChart";
import { setColors } from "./colors";

export default {
  name: "ReportTwo",
  components: {
    HorizontalBarChart,
  },
  props: {
    tab: {
      type: String,
      default: "",
    },
    filterData: {
      type: String,
      default: "",
    },
    filterList: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    loadTwo1: false,
    loading: false,
    loadLine: false,
    optionChartColumn: {},
    optionChartLine: {},
    dataTable: [],
    dataTotal: 0,
  }),
  watch: {
    filterData() {
      this.$nextTick(() => {
        this.fetchChart(true);
      });
    },
  },
  mounted() {
    this.fetchChart();
  },
  methods: {
    async fetchChart() {
      this.loading = false;
      const { data } = await this.axios.get(
        `/api/reports/two${this.filterData}`,
      );
      if (data.success) {
        this.loading = true;
        let dataChart = data.data;
        this.dataTable = [
          { label: "Fichas por vencer", value: dataChart.about_to_expire },
          { label: "Fichas vencidas", value: dataChart.expired },
        ];
        this.dataTotal = dataChart.total;
        let optionChartPie = {
          labels: ["Fichas vencidas", "Fichas por vencer"],
          datasets: [
            {
              label: "DataSet1",
              backgroundColor: [setColors[0], setColors[1]],
              data: [dataChart.expired, dataChart.about_to_expire],
            },
          ],
        };
        this.optionChartColumn = optionChartPie;
        // this.$nextTick(() => {
        //   this.optionChartColumn = optionChartPie;
        // });
        // if (flag) {
        //   this.$nextTick(() => {
        //     this.$refs.reportTwo1.renderChartReport();
        //   });
        // }
      }
    },
    async onDownloadChart(nameChart) {
      const element = document.getElementById(nameChart);
      this.loadTwo1 = true;
      var dataURL = element.toDataURL("image/png");
      let file = null;
      await fetch(dataURL)
        .then((res) => res.blob())
        .then((blob) => {
          file = new File([blob], "File name", { type: "image/png" });
        });
      const formData = new FormData();
      formData.append("file", file);
      const listFilter = this.parseFilterReport(this.filterData);
      for (let key in listFilter) {
        if (listFilter[key]) {
          formData.append(key, listFilter[key]);
        }
      }
      const { data } = await this.axios({
        method: "post",
        url: "/api/reports/two/export",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.loadTwo1 = false;
      this.$nextTick(() => {
        document.getElementById(
          "urlDownload",
        ).href = `${this.baseURL}/${data.data.url}`;
        document.getElementById("urlDownload").click();
      });
    },
  },
};
</script>
