<template>
  <v-card-text class="pb-0">
    <v-form
      ref="formUpdate"
      v-model="validUpdate"
      :lazy-validation="lazyUpdate"
    >
      <v-row>
        <v-col v-if="typeDialog !== 'new'" cols="4" class="py-0">
          <!-- <h4 class="card-input-title mb-2">Código</h4> -->
          <v-text-field
            v-model="selectedItem.id"
            readonly
            outlined
            label="Código"
            height="56"
            hide-details
            class="pt-0 input-custom-56"
          ></v-text-field>
        </v-col>
        <v-col cols="4" class="py-0">
          <v-autocomplete
            v-model="selectedType"
            class="pt-0 input-custom-56"
            label="Tipo*"
            item-text="label"
            item-value="id"
            height="56"
            outlined
            return-object
            :items="listType.options"
            :hide-details="isEmpty(listError.type_id)"
            :rules="rulesType"
            :disabled="disabledOptions"
            @input="onChangeType"
          ></v-autocomplete>
        </v-col>
        <v-col cols="4" class="py-0">
          <v-autocomplete
            v-model="selectedSituation"
            class="pt-0 input-custom-56"
            name="state_ficha"
            label="Situación*"
            item-text="label"
            item-value="id"
            height="56"
            outlined
            return-object
            :items="auxListSituation"
            :hide-details="isEmpty(listError.situation_id)"
            :rules="rulesSituation"
            :disabled="disabledOptions"
            @input="onChangeState"
          ></v-autocomplete>
        </v-col>
        <date-permisos
          ref="datePermisos"
          :situation="selectedSituation"
          :disabled="disabledOptions"
          :list-error="listError"
          @update="onChangeDate"
          @change-state="onChangeStateByDate"
        ></date-permisos>
        <v-col cols="12" class="py-0">
          <v-text-field
            v-model="selectedItem.resolution"
            outlined
            label="Resolución*"
            height="56"
            hide-details
            :readonly="disabledOptions"
            class="pt-0 input-custom-56"
            @input="onChangeResolution"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-select
            v-model="selectedItem.permission_class"
            outlined
            label="Clases de permiso*"
            height="56"
            hide-details
            :items="['Original', 'Modificado']"
            :readonly="disabledOptions"
            :disabled="disabledOptions"
            class="pt-0 input-custom-56"
            @input="onChangePermissionClass"
          ></v-select>
        </v-col>
        <v-col cols="12" class="py-0" v-if="permiso === 'Modificado' || selectedItem.permission_class !== 'Original'">
          <v-select
            v-model="selectedItem.file_relation"
            outlined
            label="Ficha de relación*"
            height="56"
            hide-details
            :items="listOriginalItems"
            item-text="file_relation_resolution"
            item-value="id"
            :readonly="disabledOptions"
            :disabled="disabledOptions"
            class="pt-0 input-custom-56"
            @input="onChangeFileRelation"
          ></v-select>
        </v-col>
        <v-col
          v-if="selectedSituation && selectedSituation.label === 'En proceso'"
          cols="12"
          class="py-0"
        >
          <!-- <h4 class="mb-2 card-input-title">Plazo</h4> -->
          <v-container fluid class="py-0 px-0 mb-3">
            <v-row>
              <v-col cols="4" class="py-0">
                <p class="mb-1 font-weight-light">Plazo Legal*</p>
                <block-counter
                  v-model="inputLegalTerm"
                  :disabled="disabledOptions"
                  @input="onChangeLegalTerm"
                ></block-counter>
              </v-col>
              <v-col cols="4" class="py-0">
                <p class="mb-1 font-weight-light">Plazo Planificado</p>
                <block-counter
                  v-model="inputPlannedTerm"
                  :disabled="disabledOptions"
                  @input="onChangePlannedTerm"
                ></block-counter>
              </v-col>
              <v-col cols="4" class="py-0">
                <p class="mb-1 font-weight-light">Plazo Real*</p>
                <block-counter
                  v-model="inputActualTerm"
                  :disabled="disabledOptions"
                  @input="onChangeActualTerm"
                ></block-counter>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <water-permit-authority
          ref="waterPermitAuthority"
          :selected-item="selectedItem"
          :disabled="disabledOptions"
          :type-module="typeModule"
          :list-error="listError"
          :type-submodule="typeSubmodule"
        ></water-permit-authority>
        <responsable-section
          ref="responsibleSection"
          :selected-item="selectedItem"
          :type-dialog="typeDialog"
          :filter-ejecutor="true"
          :type-submodule="typeSubmodule"
          :list-error="listError"
          :disabled="disabledOptions"
          @clear-error="onClearErrorResponsible"
        ></responsable-section>
        <v-col cols="12" class="py-0">
          <v-autocomplete
            v-model="selectedProject"
            class="pt-0 input-custom-56"
            label="Proyecto*"
            item-text="label"
            item-value="id"
            outlined
            height="56"
            return-object
            :hide-details="isEmpty(listError.project_id)"
            :rules="rulesProject"
            :items="listProjects.options"
            :disabled="disabledOptions"
            @input="onChangeProject"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0 mb-3">
          <v-textarea
            v-model="selectDetail"
            class="pt-0 mb-0"
            label="Detalle"
            hide-details
            outlined
            name="detalle"
            :readonly="disabledOptions"
            @input="onChangeDetail"
          ></v-textarea>
        </v-col>
        <!-- <template
          v-if="selectedSituation && selectedSituation.label === 'En proceso'"
        >
          <v-col cols="12" class="py-0 my-3">
            <v-divider></v-divider>
          </v-col>
          <check-list-task
            :id="selectedItem.id"
            class="mb-0"
            field="requirements"
            :tasks="selectedItem.requirements"
            :type="typeDialog"
            :type-module="typeModule"
            :type-submodule="typeSubmodule"
            :is-drafts="isDrafts"
            :filter-ejecutor="true"
            :disabled="disabledOptions"
            @update="onChangePendingRequirements"
          ></check-list-task>
        </template> !-->
        <v-col cols="12" class="py-0 mt-2 mb-4">
          <v-divider></v-divider>
        </v-col>
        <check-list-task
          :id="selectedItem.id"
          class="mb-0"
          :tasks="selectedItem.pending_tasks"
          :type="typeDialog"
          :type-module="typeModule"
          :type-submodule="typeSubmodule"
          :is-drafts="isDrafts"
          :filter-ejecutor="true"
          :disabled="disabledOptions"
          @update="onChangePendingTask"
        ></check-list-task>
        <v-col cols="12" class="py-0 mt-2 mb-4">
          <v-divider></v-divider>
        </v-col>
        <map-section
          :id="selectedItem.id"
          ref="mapSection"
          v-model="selectedItem.location_disabled"
          :zone="selectedItem.zone"
          :datum="selectedItem.datum"
          :locations="selectedItem.locations"
          :type-dialog="typeDialog"
          :disabled="disabledOptions"
          :type-module="typeModule"
          :type-submodule="typeSubmodule"
          :is-drafts="isDrafts"
          @location-disabled="onChangeLocationDisabled"
          @update="onChangeLocations"
          @datum-zone="onChangeDatumZone"
        ></map-section>
        <v-col cols="12" class="py-0 mt-2 mb-3">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="11" class="py-0 mb-3">
          <p class="mb-1 card-input-title">Cargo de Expediente*</p>
          <upload-file
            ref="submissionFee"
            :item-file="selectedItem.submission_fee"
            :disabled="disabledOptions"
            :is-multiple="false"
            :is-table="true"
            :text-error="listError.submission_fee"
            @update="onChangeFile($event, 'submission_fee')"
          ></upload-file>
        </v-col>
        <v-col cols="1" class="py-0" style="cursor: pointer;" align-self="center" @click="onAddSubmissionFee">
          <v-icon>add</v-icon>
        </v-col>
        <v-col cols="12" class="py-0 mb-3" v-for="(_, index) in submissionFeeItems" :key="index" v-if="submissionFeeItems.length > 0">
          <upload-file
            ref="submissionFeeArray"
            :item-file="selectedItem[`submission_fee_${index+1}`]"
            :disabled="disabledOptions"
            :is-multiple="false"
            :is-table="true"
            :text-error="listError[`submission_fee_${index+1}`]"
            @update="onChangeFile($event, `submission_fee_${index+1}`)"
          ></upload-file>
        </v-col>
        <v-col cols="12" class="py-0 mb-3">
          <p class="mb-1 card-input-title">
            Resolución de aprobación{{
              isEmpty(selectedSituation)
                ? ""
                : selectedSituation.slug === "vigente" ||
                selectedSituation.slug === "vencido"
                  ? "*"
                  : ""
            }}
          </p>
          <upload-file
            ref="approvalResolution"
            :item-file="selectedItem.approval_resolution"
            :disabled="disabledOptions"
            :is-multiple="false"
            :is-table="true"
            :text-error="listError.approval_resolution"
            @update="onChangeFile($event, 'approval_resolution')"
          ></upload-file>
        </v-col>
        <v-col cols="12" class="py-0 mb-3">
          <p class="mb-2 card-input-title">Base legal</p>
          <v-textarea
            v-model="legalBase"
            class="pt-0 mb-0"
            hide-details
            outlined
            height="100"
            label="Comentario"
            :readonly="disabledOptions"
            @input="onChangeLegalBase"
          >
          </v-textarea>
        </v-col>
        <v-col v-if="!isEmpty(spij)" cols="12" class="py-0 mb-0">
          <p class="mb-1 card-input-title">SPIJ</p>
          <v-row>
            <v-col cols="12" md="4" class="py-0">
              <a :href="spij.spij_url" target="_blank">{{ spij.spij_url }}</a>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                v-model="spij.spij_user"
                outlined
                readonly
                label="Usuario"
                height="56"
                hide-details
                append-icon="link"
                class="pt-0 input-custom-56 mb-0"
                @click:append="onCopyText('spij_user', spij.spij_user)"
              ></v-text-field>
              <input id="spij_user" type="hidden" :value="spij.spij_user" />
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                v-model="spij.spij_password"
                outlined
                readonly
                label="Contraseña"
                height="56"
                hide-details
                append-icon="link"
                class="pt-0 input-custom-56 mb-0"
                @click:append="onCopyText('spij_password', spij.spij_password)"
              ></v-text-field>
              <input
                id="spij_password"
                type="hidden"
                :value="spij.spij_password"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-card-text>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { cloneDeep, debounce } from "lodash";
import DatePermisos from "./DatePermisos";
import WaterPermitAuthority from "./WaterPermitAuthority";
import ResponsableSection from "../commons/ResponsableSection";
import CheckListTask from "../commons/CheckListTask";
import BlockCounter from "../commons/BlockCounter";
import MapSection from "../commons/MapSection";
import UploadFile from "../commons/UploadFile";

export default {
  name: "PermitSection",
  components: {
    ResponsableSection,
    DatePermisos,
    CheckListTask,
    BlockCounter,
    WaterPermitAuthority,
    MapSection,
    UploadFile,
  },
  model: { prop: "selectedItem", event: "change" },
  props: {
    selectedItem: { type: Object, default: () => {} },
    spij: { type: Object, default: () => {} },
    typeModule: { type: String, default: "" },
    typeSubmodule: { type: String, default: "" },
    typeDialog: { type: String, default: "new" },
    disabledEdit: { type: Boolean, default: false },
    isDrafts: { type: Boolean, default: false },
    listItems: {
      type: Array,
      default: [],
    }
  },
  data: () => ({
    validUpdate: false,
    lazyUpdate: false,
    form: {},
    copyText: "",
    submissionFeeItems: [],
    permiso: null,
    listOriginalItems: [],
    listPermisos: [
      {
        label: 'Original',
        value: 'Original'
      },
      {
        label: 'Modificado',
        value: 'Modificado'
      }
    ],
    selectDetail: null,
    selectedType: null,
    selectedProject: null,
    selectedSituation: null,
    selectedAuto: null,
    legalBase: null,
    inputLegalTerm: null,
    inputPlannedTerm: null,
    inputActualTerm: null,
    optionsWaterAdministrativeAuthority: { options: [] },
    selectedWaterAdministrativeAuthority: null,
    loadWaterAdmin: false,
    optionsLocalWaterAuthority: { options: [] },
    selectedLocalWaterAuthority: null,
    loadLocalWater: false,
    debounceDetail: null,
    debounceLegalBase: null,
    // rules
    rulesType: [],
    rulesProject: [],
    rulesSituation: [],
    // error
    listError: {
      type_id: "",
      situation_id: "",
      project_id: "",
      // authority
      authority_id: "",
      water_administrative_authority_id: "",
      local_water_authority_id: "",
      // user
      primary_responsible_id: "",
      secondary_responsible_id: "",
      // file
      approval_resolution: "",
      submission_fee: "",
      submission_fee_1: "",
      submission_fee_2: "",
      submission_fee_3: "",
      // date
      presentation_date: "",
      expiration_date: "",
      approval_date: "",
    },
    auxListSituation: [],
  }),
  computed: {
    ...mapState({
      listMasters: (state) => state.masters.listMasters,
      listSituations: (state) => state.masters.listSituations,
      listProjects: (state) => state.masters.listProjects,
    }),
    listType() {
      const types = this.listMasters.filter((e) => {
        return e.slug === `${this.typeModule}-${this.typeSubmodule}-tipo`;
      });
      return types.length ? types[0] : { options: [] };
    },
    disabledOptions() {
      if (this.typeDialog === "edit") {
        return !this.disabledEdit;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.debounceDetail = debounce(this.onChangeDetail, 300);
    this.debounceLegalBase = debounce(this.onChangeLegalBase, 300);
    this.listOriginalItems = this.listItems.filter((i) => i.permission_class === 'Original' && i.id !== this.selectedItem.id);
  },
  watch: {
    selectedItem() {
      if (this.selectedItem.submission_fee !== null) {
        const contains = Object.entries(this.selectedItem).map(([key, value]) => {
          if (key.includes('submission_fee')) {
            return { key, value };
          }
        }).filter((k) => k !== undefined && k.key !== 'submission_fee_size' && k.value !== null && k.key !== 'submission_fee');

        if (contains.length > 0) {
          this.submissionFeeItems.push(contains);
        }
      } else {
        this.submissionFeeItems = [];
      }
    },
  },
  methods: {
    ...mapActions(["fetchMasterByMaster", "fetchMasterOptionsByMaster"]),
    onAddSubmissionFee() {
      if (this.submissionFeeItems.length < 3) {
        this.submissionFeeItems.push(1);
      }
    },
    clearFormSection() {
      this.selectedProject = null;
      this.selectedSituation = null;
      this.selectedType = null;
      this.selectedAuto = null;
      this.valDatePresentation = null;
      this.valDateExpiration = null;
      this.valDateApproval = null;
      this.inputLegalTerm = null;
      this.inputPlannedTerm = null;
      this.inputActualTerm = null;
      this.selectDetail = null;
      this.legalBase = null;
      if (this.$refs.mapSection) this.$refs.mapSection.resetValidation();
      if (this.$refs.submissionFee) this.$refs.submissionFee.clearData();
      if (this.$refs.submissionFeeArray) this.$refs.submissionFeeArray.clearData();
      if (this.$refs.approvalResolution)
        this.$refs.approvalResolution.clearData();
      if (this.$refs.datePermisos) {
        this.$refs.datePermisos.setDates(null, null, null, false);
      }
    },
    clearErrorSection() {
      if (this.$refs.waterPermitAuthority)
        this.$refs.waterPermitAuthority.resetValidation();

      if (this.$refs.responsibleSection)
        this.$refs.responsibleSection.resetValidation();

      if (this.$refs.formUpdate) this.$refs.formUpdate.resetValidation();
      if (this.$refs.submissionFee) this.$refs.submissionFee.clearData();
      if (this.$refs.approvalResolution)
        this.$refs.approvalResolution.clearData();
      // set error
      this.$nextTick(() => {
        this.listError = {
          type_id: "",
          situation_id: "",
          project_id: "",
          // authority
          authority_id: "",
          water_administrative_authority_id: "",
          local_water_authority_id: "",
          // user
          primary_responsible_id: "",
          secondary_responsible_id: "",
          // file
          approval_resolution: "",
          submission_fee: "",
          submission_fee_1: null,
          submission_fee_2: null,
          submission_fee_3: null,
          // date
          presentation_date: "",
          expiration_date: "",
          approval_date: "",
          resolution: "",
          permission_class: "",
          file_relation: "",
          //map section
          zone_id: "",
          datum_id: "",
        };
      });
    },
    listErrorSection(err) {
      const {
        type_id = null,
        situation_id = null,
        authority_id = null,
        water_administrative_authority_id = null,
        local_water_authority_id = null,
        primary_responsible_id = null,
        secondary_responsible_id = null,
        project_id = null,
        approval_resolution = null,
        submission_fee = null,
        submission_fee_1 = null,
        submission_fee_2 = null,
        submission_fee_3 = null,
        resolution = null,
        permission_class = null,
        file_relation = null,
      } = err;

      this.listError.type_id = type_id ? type_id[0] : null;
      this.listError.situation_id = situation_id ? situation_id[0] : null;
      this.listError.authority_id = authority_id ? authority_id[0] : null;
      this.listError.resolution = resolution ? resolution[0] : null;
      this.listError.permission_class = permission_class ? permission_class[0] : null;
      this.listError.file_relation = file_relation ? file_relation[0] : null;
      this.listError.water_administrative_authority_id = water_administrative_authority_id
        ? water_administrative_authority_id[0]
        : null;
      this.listError.local_water_authority_id = local_water_authority_id
        ? local_water_authority_id[0]
        : null;
      this.listError.primary_responsible_id = primary_responsible_id
        ? primary_responsible_id[0]
        : null;
      this.listError.secondary_responsible_id = secondary_responsible_id
        ? secondary_responsible_id[0]
        : null;
      this.listError.project_id = project_id ? project_id[0] : null;
      this.listError.approval_resolution = approval_resolution
        ? approval_resolution[0]
        : null;
      this.listError.submission_fee = submission_fee ? submission_fee[0] : null;
      this.listError.submission_fee_1 = submission_fee_1 ? submission_fee_1[0] : null;
      this.listError.submission_fee_2 = submission_fee_2 ? submission_fee_2[0] : null;
      this.listError.submission_fee_3 = submission_fee_3 ? submission_fee_3[0] : null;

      this.rulesType = this.listError.type_id
        ? [() => this.listError.type_id]
        : [];
      this.rulesProject = this.listError.project_id
        ? [() => this.listError.project_id]
        : [];
      this.rulesSituation = this.listError.situation_id
        ? [() => this.listError.situation_id]
        : [];

      this.$refs.formUpdate.validate();
      this.$nextTick(() => {
        this.$refs.waterPermitAuthority.validateError(err);
        this.$refs.datePermisos.validateError(err);
        this.$refs.mapSection.validateError(err);
        this.$refs.responsibleSection.validateError(err);
        this.$refs.submissionFee.validateError(err);
        this.$refs.approvalResolution.validateError(err);
      });
    },
    setForm() {
      this.rulesType = [];
      this.rulesProject = [];
      this.rulesSituation = [];
      if (this.$refs.submissionFee) this.$refs.submissionFee.clearData();
      if (this.$refs.approvalResolution) {
        this.$refs.approvalResolution.clearData();
      }
      this.auxListSituation = cloneDeep(this.listSituations.options);
      if (
        !this.isEmpty(this.selectedItem.situation) &&
        this.typeDialog === "edit"
      ) {
        if (this.selectedItem.situation.slug === "vigente") {
          this.auxListSituation = this.auxListSituation.filter(
            (e) => e.slug !== "en-proceso",
          );
        } else if (this.selectedItem.situation.slug === "vencido") {
          this.auxListSituation = this.auxListSituation.filter(
            (e) => e.slug !== "vigente" && e.slug !== "en-proceso",
          );
        }
      }
      this.$nextTick(() => {
        const {
          detail,
          approval_date,
          expiration_date,
          presentation_date,
          authority,
          project,
          situation,
          type,
          legal_term,
          planned_term,
          actual_term,
          legal_base,
          expiration_date_disabled = false,
          resolution,
          permission_class,
          file_relation,
        } = this.selectedItem;
        this.selectedProject = project;
        this.selectedSituation = situation;
        this.selectedType = type;
        this.$refs.datePermisos.setDates(
          presentation_date,
          expiration_date,
          approval_date,
          expiration_date_disabled,
        );
        this.selectedAuto = authority;
        this.inputLegalTerm = legal_term;
        this.inputPlannedTerm = planned_term;
        this.inputActualTerm = actual_term;
        this.selectDetail = detail;
        this.legalBase = legal_base;
        if (authority) {
          this.$refs.waterPermitAuthority.setAuthority(false);
        }
        this.$refs.mapSection.setForm();
        this.$refs.responsibleSection.setForm();
      });
    },
    onDialogRequire(type) {
      this.dialogAddRequire = true;
      this.flagNewRequire = type;
    },
    onChangeFile(file, type) {
      if (type === "submission_fee") {
        this.selectedItem.submission_fee = file;
        this.listError.submission_fee = null;
      } else if(type === "submission_fee_1") {
        this.selectedItem.submission_fee_1 = file;
        this.listError.submission_fee_1 = null;
      } else if (type === "submission_fee_2") {
        this.selectedItem.submission_fee_2 = file;
        this.listError.submission_fee_2 = null;
      } else if (type === "submission_fee_3") {
        this.selectedItem.submission_fee_3 = file;
        this.listError.submission_fee_3 = null;
      } else {
        this.selectedItem.approval_resolution = file;
        this.listError.approval_resolution = null;
      }
    },
    onChangeResponsible(primary, secondary) {
      this.selectedItem.primary_responsible = primary ? primary : null;
      this.selectedItem.secondary_responsible = secondary ? secondary : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeLegalTerm(item) {
      this.selectedItem.legal_term = item ? item : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeResolution(item) {
      this.selectedItem.resolution = item ? item : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangePermissionClass(item) {
      this.selectedItem.permission_class = item ? item : null;
      this.permiso = item;

      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeFileRelation(item) {
      this.selectedItem.file_relation = item ? item : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangePlannedTerm(item) {
      this.selectedItem.planned_term = item ? item : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeActualTerm(item) {
      this.selectedItem.actual_term = item ? item : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeType(item) {
      this.selectedItem.type = item ? item : null;
      this.listError.type_id = null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeState(item) {
      this.selectedItem.situation = item ? item : null;
      this.listError.situation_id = null;
      if (item.slug === "vigente" || item.slug === "vencido") {
        this.selectedItem.expiration_date = null;
        this.selectedItem.expiration_date_disabled = true;
      }
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeStateByDate() {
      const result = this.listSituations.options.find(
        (e) => e.slug === "vigente",
      );
      this.$nextTick(() => {
        this.selectedSituation = result;
        this.onChangeState(result);
      });
    },
    onChangeProject(item) {
      this.selectedItem.project = item ? item : null;
      this.listError.project_id = null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeDetail(item) {
      this.selectedItem.detail = item ? item : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeLegalBase(item) {
      this.selectedItem.legal_base = item ? item : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeDate(presentation, expiration, approval, expirationDateDisabled) {
      this.selectedItem.presentation_date = presentation ? presentation : null;
      this.selectedItem.expiration_date = expiration ? expiration : null;
      this.selectedItem.approval_date = approval ? approval : null;
      this.selectedItem.expiration_date_disabled = expirationDateDisabled;
      if (this.selectedItem.presentation_date) {
        this.listError.presentation_date = null;
      }
      if (this.selectedItem.expiration_date) {
        this.listError.expiration_date = null;
      }
      if (this.selectedItem.approval_date) {
        this.listError.approval_date = null;
      }
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeDatumZone(datum, zone) {
      this.selectedItem.datum = datum ? datum : [];
      this.selectedItem.zone = zone ? zone : [];
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeLocationDisabled() {
      this.$emit("change-form");
    },
    onChangeLocations(locations) {
      this.selectedItem.locations = locations ? locations : [];
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangePendingTask(task) {
      this.selectedItem.pending_tasks = task ? task : [];
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangePendingRequirements(requirements) {
      this.selectedItem.requirements = requirements ? requirements : [];
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onClearErrorResponsible(type) {
      if (type === "primary") {
        this.listError.primary_responsible_id = null;
      } else if (type === "secondary") {
        this.listError.secondary_responsible_id = null;
      }
    },
    onCopyText(id, text) {
      let inputPassword = document.querySelector(`#${id}`);
      inputPassword.setAttribute("type", "text");
      inputPassword.select();
      try {
        const successful = document.execCommand("copy");
        const msg = successful ? "successful" : "unsuccessful";
        if (msg === "successful") this.copyText = "Copiado";
      } catch (err) {
        console.error(err);
      }
      inputPassword.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
      setTimeout(() => {
        this.copyText = "";
      }, 500);
    },
  },
};
</script>
