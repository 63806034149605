<template>
  <v-card class="block-report" flat tile>
    <v-btn
      v-if="loading"
      class="btn-bottom"
      fab
      dark
      small
      color="primary"
      @click="goToScroll('chartFour1', 'contentReports')"
    >
      <v-icon dark>keyboard_arrow_down</v-icon>
    </v-btn>
    <v-card-text>
      <v-row>
        <template v-if="loading">
          <v-col id="chartFour1" cols="12">
            <h4 class="subtitle-2 primary--text font-weight-black">
              Gráfico 1 de 2
            </h4>
            <bar-chart
              v-if="loading"
              ref="reportFour1"
              chart-id="reportFour1"
              :chart-data="optionChartBar1"
              :title="`${titleChart} por Proyecto`"
            ></bar-chart>
            <v-row v-if="loading">
              <v-col cols="12" class="text-left">
                <v-btn
                  class="ma-0"
                  color="action"
                  dark
                  depressed
                  small
                  :loading="loadFour1"
                  @click="onDownloadChart('reportFour1', 'four1')"
                  >Descargar Reporte
                </v-btn>
              </v-col>
              <v-col cols="12" class="text-left">
                <v-simple-table class="table-report" :dense="true">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Proyecto</th>
                        <th class="text-left">En proceso</th>
                        <th class="text-left">Vencido</th>
                        <th class="text-left">Vigente</th>
                        <th class="text-left">Total</th>
                        <th class="text-left">En proceso</th>
                        <th class="text-left">Vencido</th>
                        <th class="text-left">Vigente</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in dataTable" :key="index">
                        <td>{{ item.project }}</td>
                        <td>{{ item.in_process }}</td>
                        <td>{{ item.expired }}</td>
                        <td>{{ item.active }}</td>
                        <td>
                          {{ item.in_process + item.expired + item.active }}
                        </td>
                        <td>
                          {{ formatPercentage(item.in_process_percentage, 1) }}
                        </td>
                        <td>
                          {{ formatPercentage(item.expired_percentage, 1) }}
                        </td>
                        <td>
                          {{ formatPercentage(item.active_percentage, 1) }}
                        </td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td>{{ dataTotal.in_process }}</td>
                        <td>{{ dataTotal.expired }}</td>
                        <td>{{ dataTotal.active }}</td>
                        <td>
                          {{
                            dataTotal.in_process +
                              dataTotal.expired +
                              dataTotal.active
                          }}
                        </td>
                        <td>
                          {{
                            formatPercentage(dataTotal.in_process_percentage, 1)
                          }}
                        </td>
                        <td>
                          {{
                            formatPercentage(dataTotal.expired_percentage, 1)
                          }}
                        </td>
                        <td>
                          {{ formatPercentage(dataTotal.active_percentage, 1) }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <h4 class="subtitle-2 primary--text font-weight-black">
              Gráfico 2 de 2
            </h4>
            <bar-chart
              v-if="loading"
              ref="reportFour2"
              chart-id="reportFour2"
              :chart-data="optionChartBar2"
              :title="`${titleChart} por Proyecto (%)`"
            ></bar-chart>
            <v-row v-if="loading">
              <v-col cols="12" class="text-left">
                <v-btn
                  class="ma-0"
                  color="action"
                  dark
                  depressed
                  small
                  :loading="loadFour2"
                  @click="onDownloadChart('reportFour2', 'four2')"
                  >Descargar Reporte
                </v-btn>
              </v-col>
              <v-col cols="12" class="text-left">
                <v-simple-table class="table-report" :dense="true">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Proyecto</th>
                        <th class="text-left">En proceso</th>
                        <th class="text-left">Vencido</th>
                        <th class="text-left">Vigente</th>
                        <th class="text-left">Total</th>
                        <th class="text-left">En proceso</th>
                        <th class="text-left">Vencido</th>
                        <th class="text-left">Vigente</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in dataTable" :key="index">
                        <td>{{ item.project }}</td>
                        <td>{{ item.in_process }}</td>
                        <td>{{ item.expired }}</td>
                        <td>{{ item.active }}</td>
                        <td>
                          {{ item.in_process + item.expired + item.active }}
                        </td>
                        <td>
                          {{ formatPercentage(item.in_process_percentage, 1) }}
                        </td>
                        <td>
                          {{ formatPercentage(item.expired_percentage, 1) }}
                        </td>
                        <td>
                          {{ formatPercentage(item.active_percentage, 1) }}
                        </td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td>{{ dataTotal.in_process }}</td>
                        <td>{{ dataTotal.expired }}</td>
                        <td>{{ dataTotal.active }}</td>
                        <td>
                          {{
                            dataTotal.in_process +
                              dataTotal.expired +
                              dataTotal.active
                          }}
                        </td>
                        <td>
                          {{
                            formatPercentage(dataTotal.in_process_percentage, 1)
                          }}
                        </td>
                        <td>
                          {{
                            formatPercentage(dataTotal.expired_percentage, 1)
                          }}
                        </td>
                        <td>
                          {{ formatPercentage(dataTotal.active_percentage, 1) }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-col>
        </template>
        <template v-else>
          <v-col cols="12" class="text-center mt-5">
            <v-progress-circular
              :size="22"
              class="d-inline-block mr-3"
              :width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <span class="primary--text">Cargando reporte</span>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { cloneDeep } from "lodash";
import { setColors } from "./colors";
import BarChart from "./charts/BarMultipleChart";

const optionsChart = {
  labels: [],
  datasets: [],
};
const auxSeries = [
  {
    name: "Vigente",
    data: [],
  },
  {
    name: "Vencido",
    data: [],
  },
  {
    name: "En proceso",
    data: [],
  },
];

export default {
  name: "ReportFour",
  components: {
    BarChart,
  },
  props: {
    tab: {
      type: String,
      default: "",
    },
    filterData: {
      type: String,
      default: "",
    },
    filterList: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    isFlag: false,
    loading: false,
    loadFour1: false,
    loadFour2: false,
    optionChartBar1: {},
    optionChartBar2: {},
    optionChart: {},
    dataTable: [],
    dataTotal: 0,
  }),
  computed: {
    titleChart() {
      let label = "Estado de ";
      this.filterList.forEach((e, i) => {
        label += e.label;
        if (i < this.filterList.length - 1) label += " - ";
      });
      return this.filterList.length ? label : "Estado General";
    },
  },
  watch: {
    filterData() {
      this.$nextTick(() => {
        this.fetchChart(true);
      });
    },
  },
  mounted() {
    this.fetchChart();
  },
  methods: {
    async fetchChart() {
      this.loading = false;
      const { data } = await this.axios.get(
        `/api/reports/five${this.filterData}`,
      );
      if (data.success) {
        this.loading = true;
        let dataChart = data.data;
        this.dataTotal = {
          expired: 0,
          active: 0,
          in_process: 0,
          expired_percentage: 0,
          active_percentage: 0,
          in_process_percentage: 0,
        };
        this.dataTable = dataChart;
        dataChart.forEach((e) => {
          this.dataTotal.expired += e.expired;
          this.dataTotal.active += e.active;
          this.dataTotal.in_process += e.in_process;
          this.dataTotal.expired_percentage += e.expired_percentage;
          this.dataTotal.active_percentage += e.active_percentage;
          this.dataTotal.in_process_percentage += e.in_process_percentage;
        });
        let projects = dataChart.map((e) => e.project);
        const seriesBar1 = cloneDeep(auxSeries);
        const seriesBar2 = cloneDeep(auxSeries);
        seriesBar1[0].data = dataChart.map((e) => e.active);
        seriesBar1[1].data = dataChart.map((e) => e.expired);
        seriesBar1[2].data = dataChart.map((e) => e.in_process);
        seriesBar2[0].data = dataChart.map((e) =>
          this.formatDecimal(e.active_percentage),
        );
        seriesBar2[1].data = dataChart.map((e) =>
          this.formatDecimal(e.expired_percentage),
        );
        seriesBar2[2].data = dataChart.map((e) =>
          this.formatDecimal(e.in_process_percentage),
        );
        let optionBar1 = cloneDeep(optionsChart);
        let optionBar2 = cloneDeep(optionsChart);
        projects.forEach((e) => {
          optionBar1.labels.push(e);
        });
        seriesBar1.forEach((elem, index) => {
          optionBar1.datasets.push({
            label: elem.name,
            backgroundColor: setColors[index],
            data: elem.data,
          });
        });
        projects.forEach((e) => {
          optionBar2.labels.push(e);
        });
        seriesBar2.forEach((elem, index) => {
          optionBar2.datasets.push({
            label: elem.name,
            backgroundColor: setColors[index],
            data: elem.data,
          });
        });
        optionBar1.datasets.reverse();
        optionBar2.datasets.reverse();
        this.optionChartBar1 = optionBar1;
        this.optionChartBar2 = optionBar2;
      }
    },
    async onDownloadChart(nameChart, type) {
      const element = document.getElementById(nameChart);
      if (type === "four1") {
        this.loadFour1 = true;
      } else {
        this.loadFour2 = true;
      }
      var dataURL = element.toDataURL("image/png");
      let file = null;
      await fetch(dataURL)
        .then((res) => res.blob())
        .then((blob) => {
          file = new File([blob], "File name", { type: "image/png" });
        });
      const formData = new FormData();
      formData.append("file", file);
      const listFilter = this.parseFilterReport(this.filterData);
      for (let key in listFilter) {
        if (listFilter[key]) {
          formData.append(key, listFilter[key]);
        }
      }
      const { data } = await this.axios({
        method: "post",
        url: "/api/reports/five/export",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (type === "four1") {
        this.loadFour1 = false;
      } else {
        this.loadFour2 = false;
      }
      this.$nextTick(() => {
        document.getElementById(
          "urlDownload",
        ).href = `${this.baseURL}/${data.data.url}`;
        document.getElementById("urlDownload").click();
      });
    },
    formatDecimal(val) {
      return parseFloat((val * 100).toFixed(2));
    },
  },
};
</script>
