<template>
  <v-container fluid class="pa-0">
    <v-form
      ref="formUpdate"
      v-model="validUpdate"
      :lazy-validation="lazyUpdate"
    >
      <v-row>
        <!-- Switch Win/lose -->
        <v-col v-if="showSwitchStatus" cols="12" class="mb-2 py-0">
          <p class="mb-1 card-input-title">{{ titleStatus }}</p>
          <v-radio-group
            v-model="toggleStatus"
            :disabled="disabledOptions"
            row
            @change="onChangeStatus"
          >
            <v-radio label="Gano" :value="1"></v-radio>
            <v-radio label="Perdió" :value="0"></v-radio>
          </v-radio-group>
          <p v-if="!isEmpty(listError.status)" class="red--text">
            {{ listError.status }}
          </p>
        </v-col>
      
        <v-col v-if="showExpediente" cols="6" class="mb-4 py-0">
          <p class="mb-1 card-input-title">Código de Expediente</p>
          <!-- :disabled="disabledOptions" -->
          <v-text-field
            v-model="file_code"
            class="pt-0 mb-3 input-custom-56"
            label="Cod"
            height="56"
            outlined
            :hide-details="isEmpty(listError.file_code)"
            :rules="rulesFileCode"
            @input="onChangeFileCode"
          ></v-text-field>
          <p v-if="!isEmpty(listError.file)" class="red--text">
            {{ listError.file }}
          </p>
        </v-col>
        <!-- File -->
        <v-col v-if="showFile" cols="6" class="mb-4 py-0">
          <p class="mb-1 card-input-title">{{ titleFile }}</p>
        
          <upload-file
            ref="uploadFile"
            :item-file="fileUpload"
            :disabled="disabledOptions"
            :is-multiple="false"
            :is-table="true"
            @update="onChangeFile($event)"
          ></upload-file>
          <p v-if="!isEmpty(listError.file)" class="red--text">
            {{ listError.file }}
          </p>
        </v-col>
        <!-- Date -->
        <v-col v-if="showDate" cols="6" class="mb-4 py-0">
          <p class="mb-1 card-input-title">&nbsp;</p>
          <v-menu
            v-model="showDatePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                slot="activator"
                v-model="dateFormatted"
                :label="titleDate"
                class="my-0 pt-0 input-custom-56"
                style="margin-bottom: 0 !important"
                outlined
                height="56"
                :disabled="disabledOptions"
                :hide-details="isEmpty(listError.date)"
                :rules="rules.date"
                append-icon="event"
                readonly
                @click:append="showDatePicker = true"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="datePicker"
              no-title
              scrollable
              reactive
              :show-current="true"
              locale="es"
              :allowed-dates="allowedDates"
              @input="onChangeDate"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <!-- Checklist -->
        <check-list-task
          v-if="showTodo"
          :id="instanceId"
          ref="checklist"
          class="mb-3"
          page="supervision"
          :tasks="pendingTasks"
          :type="typeDialog"
          :disabled="disabledOptions"
          :type-module="typeModule"
          :type-submodule="typeSubmodule"
          @update="onChangePendingTask"
          @todo-step="onUpdatePendingTask"
        ></check-list-task>
        <!-- Textarea -->
        <v-col v-if="showTextarea" cols="12" class="py-0">
          <v-textarea
            v-model="inputComment"
            class="pt-0 mb-0"
            outlined
            :label="titleTextarea"
            :disabled="disabledOptions"
            :hide-details="isEmpty(listError.comment)"
            :rules="rules.comment"
            @change="onChangeTextArea"
          >
          </v-textarea>
        </v-col>
        <!-- Switch File, position bottom -->
        <!--
        <v-col
          v-if="showSwitchFile && positionFile === 'down'"
          cols="12"
          class="mb-2 py-0"
        >
          <v-switch
            v-model="toggleFile"
            :ripple="false"
            hide-details
            class="mt-3 pt-0"
            :false-value="0"
            :true-value="1"
            label="N/A"
            @change="onChangeSwitch"
          ></v-switch>
        </v-col>
        -->
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import CheckListTask from "@/components/commons/CheckListTask";
import UploadFile from "@/components/commons/UploadFile";
import { cloneDeep } from "lodash";

export default {
  name: "InstanceStep",
  components: {
    CheckListTask,
    UploadFile,
  },
  model: { prop: "changeInstance", event: "change" },
  props: {
    name: {
      type: String,
      default: "one",
    },
    changeInstance: {
      type: Boolean,
      default: false,
    },
    instanceId: {
      type: String,
      default: null,
    },
    titleStatus: {
      default: "Resultado de resolución de primera instancia",
      type: String,
    },
    showSwitchStatus: {
      default: false,
      type: Boolean,
    },
    valueSwitchStatus: {
      default: 0,
      type: [Number, String],
    },
    showSwitchFile: {
      default: true,
      type: Boolean,
    },
    positionFile: {
      default: "top",
      type: String,
    },
    valueSwitch: {
      default: 0,
      type: Number,
    },
    titleFile: {
      default: "Cargar Documento",
      type: String,
    },
    showFile: {
      default: false,
      type: Boolean,
    },
    showExpediente: {
      default: false,
      type: Boolean,
    },
    valueFile: {
      default: "",
      type: String,
    },
    titleDate: {
      default: "Fecha:",
      type: String,
    },
    showDate: {
      default: false,
      type: Boolean,
    },
    valueDate: {
      default: "",
      type: String,
    },
    showTodo: {
      default: false,
      type: Boolean,
    },
    valueTodo: {
      default: () => [],
      type: Array,
    },
    titleTextarea: {
      default: "Medidas Preventivas:",
      type: String,
    },
    showTextarea: {
      default: false,
      type: Boolean,
    },
    valueTextarea: {
      default: "",
      type: String,
    },
    disabledOptions: {
      default: false,
      type: Boolean,
    },
    typeDialog: {
      type: String,
      default: "new",
    },
    typeModule: {
      type: String,
      default: "",
    },
    typeSubmodule: {
      type: String,
      default: "",
    },
    
  },
  watch: {
    /* valueTextarea(newValue, oldVal) {
      this.inputComment = newValue;
    },
    valueFile(newValue, oldVal) {
     // this.inputComment = newValue;
      this.descargarYAsignarArchivo(newValue)
    } */
    valueTextarea: {
    handler(newValue) {
      this.inputComment = newValue;
    },
    immediate: true,
  },
 /*  valueFile: {
    handler(newValue) {
      this.descargarYAsignarArchivo(newValue)
    },
    immediate: true,
  }, */
  },
  data: () => ({
    validUpdate: false,
    lazyUpdate: false,
    toggleStatus: 0,
    toggleFile: 0,
    datePicker: null,
    showDatePicker: false,
    fileUpload: null,
    pendingTasks: [],
    auxTasks: [],
    inputComment: null,
    listError: {
      file: null,
      date: null,
      status: null,
      comment: null,
    },
    flagEdit: false,
    rules: {
      date: [],
      comment: [],
    },
  }),
  computed: {

    dateFormatted() {
      return this.formatDate(this.datePicker);
    },
    
  },
  methods: {

    setListError(err) {
      this.$nextTick(() => {
        this.rules.date = [(v) => !this.isEmpty(v) || this.listError.date];
        this.rules.comment = [
          (v) => !this.isEmpty(v) || this.listError.comment,
        ];
      });
      const { date = null, file = null, comment = null, status = null } = err;
      // this.showError = true;
      this.listError.date = date;
      this.listError.file = file;
      this.listError.comment = comment;
      this.listError.status = status;
      this.$refs.formUpdate.validate();
    },

    setForm() {
      this.rules = {
        date: [],
        comment: [],
      };
      this.listError.date = null;
      this.listError.file = null;
      this.listError.comment = null;
      this.listError.status = null;
      if (this.showSwitchStatus) {
        this.toggleStatus = parseInt(this.valueSwitchStatus);
      }
      // if (this.showSwitchFile) {
      //   this.toggleFile = parseInt(this.valueSwitch);
      // }
      if (this.showFile) {
        this.fileUpload = cloneDeep(this.valueFile);
      }
      if (this.showDate) {
        this.datePicker = cloneDeep(this.valueDate);
      }
      if (this.showTodo) {
        this.pendingTasks = cloneDeep(this.valueTodo);
      }
      if (this.showTextarea) {
        this.inputComment = cloneDeep(this.valueTextarea);
      }
    },

    clearForm() {
      this.toggleStatus = 0;
      this.$refs.uploadFile.clearData();
      this.datePicker = null;
      this.showDatePicker = false;
      this.showExpediente = false;
      this.fileUpload = null;
      this.pendingTasks = [];
      this.inputComment = null;
    },

    onChangeStatus() {
      if (this.showSwitchStatus) {
        this.$emit("change", true);
      }
      this.flagEdit = true;
      this.emitChangeInstance();
    },
   
    onChangeFile(file) {
      this.listError.file = null;
      this.fileUpload = file;
      if (this.showFile) {
        this.$emit("change", true);
      }
      this.flagEdit = true;
      this.emitChangeInstance();
    },

    onChangeDate() {
      this.showDatePicker = false;
      if (this.showDate) {
        this.$emit("change", true);
      }
      this.flagEdit = true;
      this.emitChangeInstance();
    },

    onChangeTextArea() {
      if (this.showTextarea) {
        this.$emit("change", true);
      }
      this.flagEdit = true;
      this.emitChangeInstance();
    },

    onChangePendingTask(tasks) {
      this.auxTasks = tasks;
      if (this.showTodo) {
        this.$emit("change", true);
      }
      this.flagEdit = true;
      this.emitChangeInstance();
    },

    emitChangeInstance() {
      const payload = {};
      if (this.showSwitchStatus) {
        payload["status"] = parseInt(this.toggleStatus);
      }
      // if (this.showSwitchFile) {
      //   payload["toggle"] = parseInt(this.toggleFile);
      // }
      if (this.showFile) {
        payload["file"] = this.fileUpload;
      }
      if (this.showDate) {
        payload["date"] = this.datePicker;
      }
      if (this.showTextarea) {
        payload["comment"] = this.inputComment;
      }
      if (this.showTodo) {
        payload["todo"] = this.auxTasks;
      }
      this.$emit("update", this.name, payload);
    },

    formatDate(date) {
      if (!date) return null;
      date = date.split(" ");
      const [year, month, day] = date[0].split("-");
      return `${day}/${month}/${year}`;
    },

    allowedDates(val = true) {
      // const actDate = new Date();
      // return new Date(val) > actDate.setDate(actDate.getDate() - 1);
      return val;
    },

    updateLoadChecklist(flag) {
      this.$refs.checklist.updateLoadApp(flag);
    },

    onUpdatePendingTask(item) {
      this.$refs.checklist.updateLoadApp(true);
      this.$emit("update-todo", { item, name: this.name });
      // this.loopToDoStep(item.entity_id, [item], 0, true);
    },

    addPendingTask(tasks) {
      this.auxTasks.push(tasks);
    },

    /* async convertUrlToFile(url) {
      return fetch(url)
          .then(response => response.blob())
          .then(blob => {
          // Crear un nuevo objeto File con el blob obtenido
          const fileName = url.substring(url.lastIndexOf('/') + 1);
          return new File([blob], fileName);
          });
    }, */

    /* async devolverFile(url_file){
        try {
            const file = await this.convertUrlToFile(url_file);
            return file;
        } catch (error) {
            // Manejar el error aquí si es necesario
            // return null;
        }
    }, */

    
    async descargarYAsignarArchivo(urlArchivo) {
      if(!urlArchivo){
        return;
      }
      const archivo = await this.devolverFile(urlArchivo);
     // console.log(archivo);
      if (archivo) {
        //console.log(this.$refs.uploadFile.files);
        this.$refs.uploadFile.files = archivo;
        //console.log(this.$refs.uploadFile.files);

        // Asignar el archivo al componente upload-file
        //this.fileUpload = archivo;
       
        //this.$refs.uploadFile = archivo

        // También puedes llamar a la función onChangeFile si es necesario
        this.onChangeFile(archivo);
      } 
    },
  },
};
</script>
