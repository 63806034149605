<template>
  <v-card-text class="pb-0">
    <v-form
      ref="formUpdate"
      v-model="validUpdate"
      :lazy-validation="lazyUpdate"
    >
      <v-row>
        <v-col v-if="typeDialog !== 'new'" cols="4" class="py-0">
          <v-text-field
            v-model="selectedItem.id"
            class="pt-0 input-custom-56"
            label="Código"
            height="56"
            disabled
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4" class="py-0">
          <v-autocomplete
            v-model="selectedType"
            class="pt-0 input-custom-56"
            height="56"
            item-text="label"
            item-value="id"
            label="Tipo*"
            outlined
            return-object
            :hide-details="isEmpty(listError.type_id)"
            :items="listType.options"
            :rules="rulesType"
            :disabled="disabledOptions"
            @input="onChangeType"
          ></v-autocomplete>
        </v-col>
        <v-col cols="4" class="py-0">
          <v-autocomplete
            v-model="selectedSituation"
            class="pt-0 input-custom-56"
            height="56"
            item-text="label"
            item-value="id"
            label="Situación*"
            outlined
            return-object
            :items="listSituations.options"
            :disabled="disabledOptions"
            :hide-details="isEmpty(listError.situation_id)"
            :rules="rulesSituation"
            @input="onChangeSituation"
          ></v-autocomplete>
        </v-col>
        <v-col cols="4" class="py-0 mb-5">
          <v-row>
            <v-col cols="12" class="pr-3 py-0">
              <v-menu
                ref="pickerEnd"
                v-model="showDateExpiration"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    slot="activator"
                    v-model="dateFormattedExpiration"
                    label="Fecha de Vencimiento*"
                    class="my-0 pt-0 input-custom-56"
                    style="margin-bottom: 0 !important;"
                    outlined
                    height="56"
                    :disabled="!!expirationDateDisabled || disabledOptions"
                    :hide-details="isEmpty(listError.expiration_date)"
                    :rules="rulesExpiration"
                    append-icon="event"
                    readonly
                    @click:append="showDateExpiration = true"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateExpiration"
                  no-title
                  scrollable
                  reactive
                  :show-current="true"
                  locale="es"
                  :allowed-dates="allowedDates"
                  @input="onChangeDateExpiration"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" class="mt-0 py-0">
              <v-switch
                v-model="expirationDateDisabled"
                :ripple="false"
                :disabled="disabledOptions"
                hide-details
                class="mt-0"
                :false-value="0"
                :true-value="1"
                label="N/A"
                @change="onChangeExpirationDateDisabled"
              ></v-switch>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="!isEmpty(selectedSituation)" cols="12" class="py-0 mb-5">
          <v-container fluid class="py-0 px-0">
            <v-row>
              <v-col
                v-if="selectedSituation.slug === 'en-proceso'"
                cols="4"
                class="py-0"
              >
                <p class="mb-1 font-weight-light">Plazo planificado*</p>
                <block-counter
                  v-model="inputPlannedTerm"
                  :disabled="disabledOptions"
                  @input="onChangePlannedTerm"
                ></block-counter>
              </v-col>
              <v-col
                v-if="selectedSituation.slug === 'vigente'"
                cols="4"
                class="py-0"
              >
                <p class="mb-1 font-weight-light">Plazo del Contrato*</p>
                <block-counter
                  v-model="inputContractTerm"
                  :disabled="disabledOptions"
                  @input="onChangeContractTerm"
                ></block-counter>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="12" class="py-0 mb-5">
          <v-textarea
            v-model="inputParts"
            class="pt-0 mb-0"
            label="Partes*"
            outlined
            :hide-details="isEmpty(listError.parts)"
            :rules="rulesParts"
            :disabled="disabledOptions"
            @input="debounceParts"
          ></v-textarea>
        </v-col>
        <responsable-section
          ref="responsibleSection"
          :selected-item="selectedItem"
          :type-dialog="typeDialog"
          :filter-ejecutor="true"
          :type-submodule="typeSubmodule"
          :list-error="listError"
          :disabled="disabledOptions"
          @clear-error="onClearErrorResponsible"
        ></responsable-section>
        <v-col cols="12" class="py-0 mb-5">
          <v-autocomplete
            v-model="selectedProject"
            class="pt-0 input-custom-56"
            style="margin-bottom: 0 !important;"
            label="Proyecto*"
            item-text="label"
            item-value="id"
            outlined
            height="56"
            return-object
            :hide-details="isEmpty(listError.project_id)"
            :rules="rulesProject"
            :items="listProjects.options"
            :disabled="disabledOptions"
            @input="onChangeProject"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0 mb-5">
          <v-textarea
            v-model="inputDetail"
            class="pt-0 mb-0"
            label="Detalle"
            hide-details
            outlined
            :disabled="disabledOptions"
            @input="onChangeDetail"
          ></v-textarea>
        </v-col>
        <v-col cols="12" class="py-0 mb-3">
          <v-textarea
            v-model="inputMainObligations"
            class="pt-0 mb-0"
            label="Obligaciones principales del contrato"
            hide-details
            outlined
            :disabled="disabledOptions"
            @input="debounceMainObligations"
          ></v-textarea>
        </v-col>
        <v-col cols="12" class="py-0 my-3">
          <v-divider></v-divider>
        </v-col>
        <check-list-task
          :id="selectedItem.id"
          class="mb-0"
          :tasks="selectedItem.pending_tasks"
          :type="typeDialog"
          :type-module="typeModule"
          :type-submodule="typeSubmodule"
          :disabled="disabledOptions"
          :filter-ejecutor="true"
          :is-drafts="isDrafts"
          @update="onChangePendingTask"
        ></check-list-task>
        <v-col cols="12" class="py-0 mt-2 mb-3">
          <v-divider></v-divider>
        </v-col>
        <map-section
          :id="selectedItem.id"
          ref="mapSection"
          v-model="selectedItem.location_disabled"
          :zone="selectedItem.zone"
          :datum="selectedItem.datum"
          :locations="selectedItem.locations"
          :type-dialog="typeDialog"
          :disabled="disabledOptions"
          :type-module="typeModule"
          :type-submodule="typeSubmodule"
          :is-drafts="isDrafts"
          @location-disabled="onChangeLocationDisabled"
          @update="onChangeLocations"
          @datum-zone="onChangeDatumZone"
        ></map-section>
      </v-row>
    </v-form>
  </v-card-text>
</template>
<script>
import { mapState } from "vuex";
import { debounce } from "lodash";
import ResponsableSection from "../commons/ResponsableSection";
import CheckListTask from "../commons/CheckListTask";
import BlockCounter from "../commons/BlockCounter";
import MapSection from "../commons/MapSection";
export default {
  name: "ContractsSection",
  components: {
    ResponsableSection,
    CheckListTask,
    BlockCounter,
    MapSection,
  },
  model: { prop: "selectedItem", event: "change" },
  props: {
    selectedItem: { type: Object, default: () => {} },
    typeModule: { type: String, default: "" },
    typeSubmodule: { type: String, default: "" },
    typeDialog: { type: String, default: "new" },
    disabledEdit: { type: Boolean, default: false },
    isDrafts: { type: Boolean, default: false },
  },
  data() {
    return {
      validUpdate: false,
      lazyUpdate: false,
      form: {},
      inputDetail: null,
      selectedType: null,
      selectedProject: null,
      selectedSituation: null,
      selectedObligations: null,
      inputPlannedTerm: null,
      inputContractTerm: null,
      inputParts: null,
      inputMainObligations: null,
      // date picker
      dateExpiration: null,
      showDateExpiration: false,
      expirationDateDisabled: false,
      // debounce
      debounceDetail: null,
      debounceParts: null,
      debounceMainObligations: null,
      // rules
      rulesType: [],
      rulesProject: [],
      rulesParts: [],
      rulesSituation: [],
      rulesExpiration: [],
      // error
      listError: {
        type_id: "",
        situation_id: "",
        project_id: "",
        parts: "",
        // user
        primary_responsible_id: "",
        secondary_responsible_id: "",
        // date
        expiration_date: "",
      },
    };
  },
  computed: {
    ...mapState({
      listMasters: (state) => state.masters.listMasters,
      listSituations: (state) => state.masters.listSituations,
      listProjects: (state) => state.masters.listProjects,
    }),
    listType() {
      const types = this.listMasters.filter((e) => {
        return e.slug === `${this.typeModule}-${this.typeSubmodule}-tipo`;
      });
      return types.length ? types[0] : { options: [] };
    },
    dateFormattedExpiration() {
      return this.formatDate(this.dateExpiration);
    },
    disabledOptions() {
      if (this.typeDialog === "edit") {
        return !this.disabledEdit;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.debounceDetail = debounce(this.onChangeDetail, 300);
    this.debounceParts = debounce(this.onChangeParts, 300);
    this.debounceMainObligations = debounce(this.onChangeMainObligations, 300);
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const parseDate = date.split(" ")[0];
      const [year, month, day] = parseDate.split("-");
      return `${day}/${month}/${year}`;
    },
    clearErrorSection() {
      if (this.$refs.responsibleSection)
        this.$refs.responsibleSection.resetValidation();

      if (this.$refs.mapSection) this.$refs.mapSection.resetValidation();

      if (this.$refs.formUpdate) this.$refs.formUpdate.resetValidation();
      // set errror
      this.$nextTick(() => {
        this.listError = {
          type_id: "",
          situation_id: "",
          project_id: "",
          parts: "",
          // user
          primary_responsible_id: "",
          secondary_responsible_id: "",
          // date
          expiration_date: "",
        };
      });
    },
    listErrorSection(err) {
      const {
        type_id = null,
        situation_id = null,
        authority_id = null,
        parts = null,
        project_id = null,
        expiration_date = null,
      } = err;
      this.showError = true;
      this.listError.parts = parts ? parts[0] : null;
      this.listError.type_id = type_id ? type_id[0] : null;
      this.listError.situation_id = situation_id ? situation_id[0] : null;
      this.listError.authority_id = authority_id ? authority_id[0] : null;
      this.listError.project_id = project_id ? project_id[0] : null;
      this.listError.expiration_date = expiration_date
        ? expiration_date[0]
        : null;
      // Rules
      this.rulesParts = this.listError.parts
        ? [() => this.listError.parts]
        : [];
      this.rulesType = this.listError.type_id
        ? [() => this.listError.type_id]
        : [];
      this.rulesProject = this.listError.project_id
        ? [() => this.listError.project_id]
        : [];
      this.rulesSituation = this.listError.situation_id
        ? [() => this.listError.situation_id]
        : [];
      this.rulesExpiration = this.listError.expiration_date
        ? [() => this.listError.expiration_date]
        : [];
      this.$refs.formUpdate.validate();
      this.$nextTick(() => {
        this.$refs.responsibleSection.validateError(err);
        this.$refs.mapSection.validateError(err);
      });
    },
    setForm() {
      this.rulesParts = [];
      this.rulesType = [];
      this.rulesProject = [];
      this.rulesSituation = [];
      this.rulesExpiration = [];
      if (this.$refs.mapSection) this.$refs.mapSection.resetValidation();
      if (this.$refs.responsibleSection)
        this.$refs.responsibleSection.resetValidation();
      this.$nextTick(() => {
        const {
          detail,
          expiration_date,
          project,
          situation,
          type,
          parts,
          main_obligations,
          planned_term,
          contract_term,
          expiration_date_disabled = false,
        } = this.selectedItem;
        this.selectedProject = project;
        this.selectedSituation = situation;
        this.selectedType = type;
        this.inputPlannedTerm = planned_term;
        this.inputContractTerm = contract_term;
        this.inputMainObligations = main_obligations;
        this.inputParts = parts;
        this.inputDetail = detail;
        this.dateExpiration = expiration_date;
        this.expirationDateDisabled = expiration_date_disabled;
        this.$refs.mapSection.setForm();
        this.$refs.responsibleSection.setForm();
      });
    },
    onChangeType(item) {
      this.selectedItem.type = item ? item : null;
      this.listError.type_id = null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeSituation(item) {
      this.selectedItem.situation = item ? item : null;
      this.listError.situation_id = null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeResponsible(primary, secondary) {
      this.selectedItem.primary_responsible = primary ? primary : null;
      this.selectedItem.secondary_responsible = secondary ? secondary : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangePlannedTerm(item) {
      this.selectedItem.planned_term = item ? item : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeContractTerm(item) {
      this.selectedItem.contract_term = item ? item : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeLocationDisabled() {
      this.$emit("change-form");
    },
    onChangeProject(item) {
      this.selectedItem.project = item ? item : null;
      this.listError.project_id = null;
      this.rulesProject = [];
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onClearErrorResponsible(type) {
      if (type === "primary") {
        this.listError.primary_responsible_id = null;
      } else if (type === "secondary") {
        this.listError.secondary_responsible_id = null;
      }
    },
    onChangeDetail(item) {
      this.selectedItem.detail = item ? item : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeMainObligations(item) {
      this.selectedItem.main_obligations = item ? item : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeParts(item) {
      this.listError.parts = null;
      this.selectedItem.parts = item ? item : null;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeExpirationDateDisabled(flag) {
      this.selectedItem.expiration_date_disabled = flag;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    clearFormSection() {
      this.selectedProject = null;
      this.selectedSituation = null;
      this.selectedType = null;
      this.dateExpiration = null;
      this.inputPlannedTerm = null;
      this.inputContractTerm = null;
      this.inputDetail = null;
      this.inputContractTerm = null;
      this.inputMainObligations = null;
    },
    onChangeDateExpiration(expiration) {
      this.selectedItem.expiration_date = expiration ? expiration : null;
      this.selectedItem.expiration_date_disabled = this.expirationDateDisabled;
      if (this.selectedItem.expiration_date) {
        this.listError.expiration_date = "";
      }
      this.showDateExpiration = false;
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeDatumZone(datum, zone) {
      this.selectedItem.datum = datum ? datum : [];
      this.selectedItem.zone = zone ? zone : [];
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangeLocations(locations) {
      this.selectedItem.locations = locations ? locations : [];
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    onChangePendingTask(task) {
      this.selectedItem.pending_tasks = task ? task : [];
      this.$emit("change", this.selectedItem);
      this.$emit("change-form");
    },
    allowedDates(val) {
      // const actDate = new Date();
      // return new Date(val) > actDate.setDate(actDate.getDate() - 1);
      return true;
    },
  },
};
</script>
