<template>
  <v-navigation-drawer
    class="sectionDrawer"
    stateless
    hide-overlay
    width="250"
    value="true"
    absolute
    permanent
    left
    style="height: calc(100% - 65px) !important; top: 65px !important"
  >
    <vue-perfect-scrollbar
      class="drawer-filter--scroll"
      :settings="scrollSettings"
    >
      <div class="block-filter">
        <v-row>
          <v-col cols="12" class="mb-2 py-0">
            <span class="font-weight-bold">Filtrar</span>
          </v-col>
        </v-row>
        <v-row class="mb-3">
          <v-col cols="12" class="py-0">
            <v-autocomplete
              v-model="selectedMenu"
              :items="listModule"
              class="mt-0 pt-0 input-custom-56"
              label="Módulo"
              placeholder="Seleccione opción"
              item-text="label"
              item-value="id"
              clearable
              height="56"
              outlined
              hide-details
              return-object
              @input="debounceModule"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-autocomplete
              v-model="selectedSubMenu"
              :items="optionsSelectSub"
              :disabled="disabledSubmenu"
              class="mt-0 pt-0 input-custom-56"
              label="Submódulo"
              placeholder="Seleccione opción"
              item-text="label"
              item-value="id"
              clearable
              height="56"
              outlined
              hide-details
              return-object
              @input="debounceChange"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-autocomplete
              v-model="selectedProject"
              :items="listProjects.options"
              class="mt-0 pt-0 input-custom-56"
              label="Proyecto"
              placeholder="Seleccione proyecto"
              item-text="label"
              item-value="id"
              clearable
              height="56"
              outlined
              hide-details
              return-object
              @input="debounceChange"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" class="py-0">
            <selector-user
              v-model="selectedResponsible"
              @update="onSearch"
            ></selector-user>
          </v-col>
          <v-col cols="12" class="pt-0 pb-1">
            <v-row>
              <v-col cols="12" md="12" class="py-0">
                <v-autocomplete
                  v-model="selectedFulfilled"
                  :items="optionsFulfilled"
                  class="mt-0 pt-0 input-custom-56"
                  label="Atributo Extendido"
                  placeholder="Seleccione opción"
                  item-text="group"
                  item-value="slug"
                  clearable
                  height="56"
                  outlined
                  hide-details
                  return-object
                  @input="debounceModule"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pt-0 pb-1">
            <v-row>
              <v-col cols="12" md="12" class="py-0">
                <v-btn
                  class="ma-0"
                  color="action"
                  dark
                  depressed
                  small
                  block
                  @click="clearFilters()"
                  >Limpiar
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </vue-perfect-scrollbar>
  </v-navigation-drawer>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import SelectorUser from "@/components/commons/SelectorUser";
import { mapState } from "vuex";
import { debounce } from "lodash";

export default {
  name: "ReportDrawer",
  components: {
    VuePerfectScrollbar,
    SelectorUser,
  },
  data: () => ({
    selectedMenu: null,
    selectedSubMenu: null,
    disabledSubmenu: true,
    selectedProject: null,
    selectedResponsible: null,
    selectedFulfilled: null,
    optionsSelectSub: [],
    optionsFulfilled: [],
    scrollSettings: {
      maxScrollbarLength: 160,
    },
    debounceChange: null,
    debounceModule: null,
  }),
  computed: {
    ...mapState({
      listModule: (state) => state.modules.modules,
      listProjects: (state) => state.masters.listProjects,
    }),
  },
  watch: {
    selectedMenu(val) {
      if (val) {
        this.disabledSubmenu = false;
        this.optionsSelectSub = val.submodules;
      }
    },
  },
  mounted() {
    this.debounceModule = debounce(this.onChangeModule, 700);
    this.debounceChange = debounce(this.onSearch, 900);
    this.onFilterExtendedAttributes();
  },
  methods: {
    clearFilters() {
      this.selectedMenu = null;
      this.selectedSubMenu = null;
      this.selectedResponsible = null;
      this.selectedProject = null;
      this.selectedFulfilled = null;
      this.optionsSelectSub = [];
      this.checkAlert = false;
    },
    onChangeModule() {
      this.onSearch();
    },
    onFilterExtendedAttributes() {
      this.axios.get(`/api/extended_attributes_values`).then(({ data }) => {
        if (data.success) {
          this.optionsFulfilled = data.data.flatMap(entry =>
            entry.attributes.filter(attribute => attribute.data_type === 'boolean')
              .map(attribute => ({ group: entry.group, ...attribute }))
          ).map((attribute) => ({ group: `Cumple por ${attribute.group}`, slug: attribute.slug }));
        }
      });
    },
    onSearch() {
      let filterData = "?";
      let filterList = [];
      if (!this.isEmpty(this.selectedMenu)) {
        filterData += `&module_id=${this.selectedMenu.id}`;
        filterList.push({
          type: "module",
          label: this.selectedMenu.label,
        });
        this.$emit('onModule', this.selectedMenu.label);
      }
      if (!this.isEmpty(this.selectedSubMenu)) {
        filterData += `&submodule_id=${this.selectedSubMenu.id}`;
        filterData += `&submodule_slug=${this.selectedSubMenu.slug}`;
        filterList.push({
          type: "submodule",
          label: this.selectedSubMenu.label,
        });
      }
      if (!this.isEmpty(this.selectedProject)) {
        filterData += `&project_id=${this.selectedProject.id}`;
      }
      if (!this.isEmpty(this.selectedResponsible)) {
        filterData += `&responsible_id=${this.selectedResponsible.id}`;
      }
      if (!this.isEmpty(this.selectedFulfilled)) {
        filterData += `&fulfilled=${this.selectedFulfilled.slug}`;
      }
      if (
        filterList.length === 3 ||
        !this.isEmpty(this.selectedProject) ||
        !this.isEmpty(this.selectedResponsible) ||
        !this.isEmpty(this.selectedFulfilled)
      ) {
        this.$emit("update");
      }
      this.$emit("filter", filterList, filterData);
    },
  },
};
</script>
